import FyncomLogo from '../../assets/img/logo_circle.png';
import { Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { ChevronsRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { setStepCount } from '../../redux/tutor/actions';

function WelcomScreenModal(props) {
  const dispatch = useDispatch();

  return (
    <Modal
      show={props.show}
      onHide={props.close}
      centered={true}
      size="xl"
      className="customer-welcome-modal"
    >
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="welcome-left-container">
                <div className="welcome_str">Welcome to FynCom!</div>
                <div className="welcome-modal-headline-str">
                  FynCom is an omnichannel rewards tool that helps increase your
                  conversion rates through your customer's journey.
                </div>
                <ul className="text-center list-no-style">
                  <li>
                    <div className="welcome-modal-main-point-str">
                      Step 1. Create a Reward Campaign <br></br>
                      Step 2. Send Rewards email to yourself <br></br>
                      Step 3. Respond and get rewarded<br></br>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="welcome_logo_position">
                <img src={FyncomLogo} className="w-25 h-25" />
              </div>
              <div>
                <div className="react-player-thumbnail">
                  <ReactPlayer
                    height="90%"
                    width="90%"
                    url="https://www.youtube.com/embed/KLOZamnnjBA"
                    playing={false}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 0 }
                      }
                    }}
                    onPlay={() => {
                      // console.log('i am here');
                    }}
                  />
                  Check out this video for a preview of the onboarding journey
                </div>
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={props.close}
                  className="m-2"
                >
                  Skip
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    dispatch(setStepCount(2));
                  }}
                  className="m-2"
                >
                  Getting Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WelcomScreenModal;
