import { CustomerTypes } from './types';

export const getCustomerInfo = (auth0Id, remainingRetrial) => {
  if (remainingRetrial === undefined) remainingRetrial = 5;

  return {
    type: CustomerTypes.GET_INFO,
    payload: { auth0Id, remainingRetrial }
  };
};

// no clue wtf this does
export const getCustomerPreference = (preference) => {
  return {
    type: CustomerTypes.GET_PREFERENCE,
    payload: { preference }
  };
};

export const getCustomerInfoSuccess = (customerInfo) => {
  return {
    type: CustomerTypes.GET_INFO_SUCCESS,
    payload: { customerInfo }
  };
};

export const getCustomerInfoFailed404 = (auth0Id, remainingRetrial) => {
  return {
    type: CustomerTypes.GET_INFO_FAILED_404,
    payload: { auth0Id, remainingRetrial }
  };
};

export const createOrganizationSuccess = (organization) => {
  return {
    type: CustomerTypes.CREATE_ORGANIZATION_SUCCESS,
    payload: [organization]
  };
};

// This was created, but I don't think this is needed since we stuff everything inside of customer info object.
export const createOrganizationPreferenceSuccess = (organization) => {
  return {
    type: CustomerTypes.CREATE_ORGANIZATION_PERFERENCE_SUCCESS,
    payload: [organization]
  };
};

export const getOrganizationInfo = () => {
  return {
    type: CustomerTypes.GET_ORGANIZATION_INFO
  };
};

export const getOrganizationInfoSuccess = (organization) => {
  return {
    type: CustomerTypes.GET_ORGANIZATION_INFO_SUCCESS,
    payload: [organization]
  };
};

export const setAuthToken = (state) => {
  return {
    type: CustomerTypes.SET_AUTH_TOKEN,
    payload: state
  };
};

export const clearCustomer = () => {
  return {
    type: CustomerTypes.CLEAR_CUSTOMER
  };
};
