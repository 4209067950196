import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import NotyfContext from '../../contexts/NotyfContext';
import { loginUserSuccess, userPreference } from '../../redux/auth/actions';
// import { userPreference } from '../../redux/auth/actions';
import { getCustomerInfo, setAuthToken } from '../../redux/customer/actions';
import { getUserGuid } from '../../utils/auth';
import { getCustomerInfoSuccess } from '../../redux/customer/actions';
import { constantKeys } from '../../constants';
import { getData } from '../../utils/api';
import { ApiRoutes } from '../../constants/api-routes';
import { Carousel } from 'react-bootstrap';
import Carousel1 from '../../assets/img/carousel-1-ROI-global-maximize-v4.png';
import Carousel2 from '../../assets/img/carousel-2-smart-data-economy-v4.png';
import Carousel3 from '../../assets/img/carousel-3-simple-rewards-fractal-v4.png';
import Carousel4 from '../../assets/img/carousel-4-scalable-rewards-AB-test-v4.png';

const LoadingBar = () => (
  <div className="page-content d-flex justify-content-center">
    <div className="d-inline">
      <PuffLoader color="#3f80ea" />
      <span>Logging in....</span>
    </div>
  </div>
);

const SignInSuccess = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const notificationState = useSelector((state) => state.Notification);
  const [auth0Id, setAuth0Id] = useState();
  const [isReadingFinished, setReadingFinished] = useState(false);
  const [isCustomerInfoFetched, setCustomerInfo] = useState();

  useEffect(() => {
    console.log('SignInSuccess JS');
    async function getToken() {
      const token = await getAccessTokenSilently();
      if (token) {
        dispatch(setAuthToken(token));
        localStorage.setItem(constantKeys.AUTH_TOKEN, token);
        const auth0Id = getUserGuid(user.sub);
        setAuth0Id(auth0Id);
        dispatch(getCustomerInfo(auth0Id));
        dispatch(loginUserSuccess(user));
      }
    }

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  const fetchCustomerInfo = async () => {
    try {
      let response = await getData(
        ApiRoutes.getCustomerAccountInfoRoute(auth0Id)
      );
      dispatch(getCustomerInfoSuccess(response));
      dispatch(userPreference(auth0Id));
      setCustomerInfo(response);
      if (response.emailVerified) {
        if (auth0Id) navigate(state.pathname || '/dashboard');
      }
    } catch (error) {
      console.log(error);
      setCustomerInfo(null);
    }
  };

  /**
   * Checks the Auth0 Remote Records, then calls fetchCustomerInfo ONCE to check FynCom local records
   *
   * Finally, sets Auth0Id to nothing if no verifieed customer details found. This starts the carousel
   */
  useEffect(() => {
    if (user && user.sub && auth0Id) {
      if (!isCustomerInfoFetched) {
        fetchCustomerInfo();
      }
    }
    return () => {
      setAuth0Id();
    };
  }, [state, navigate, auth0Id, isCustomerInfoFetched]);

  useEffect(() => {
    if (isReadingFinished) {
      navigate('/dashboard');
    }
  }, [isReadingFinished]);

  const carouselUI = () => {
    return (
      <div className="carousel_container">
        <Carousel
          interval={8000} //8 second intervals to help with people who don't see the arrow buttons
          //changed next arrow to very obvious wording... so people don't get stuck looking for an arrow (user feedback)
          nextIcon={
            <span
              style={{
                fontSize: `80px`,
                color: '#FF5A5F',
                backgroundColor: '#FFFFFF',
                border: 'solid 2px black',
                display: 'inline-block',
                height: '.75em',
                lineHeight: '.75em'
              }}
            >
              >
            </span>
          }
          indicators={false}
          wrap={false} // prevents the back button on 1st slide from going to   home page
          keyboard={false} // Slow the person down for ~30 seconds while AWS SQS registers the account into our backend.
          onSelect={(e) => {
            if (e === 4) {
              setReadingFinished(true);
            }
          }}
        >
          <Carousel.Item>
            <div>
              <h1 className="text-center">
                While we setup your account, read how FynCom helps you.
              </h1>
              <p style={{ fontSize: 20 }}>
                <b>Maximize ROI.</b> Get more responses with
                <b> Interactive Rewards </b>
                for digital communications. Enhance your strategies in digital
                marketing for sales, product feedback, surveys & more!
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel1}
                alt="image of world map on 2d plane with wavy transparent sheet in front of the map"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">
                This is your story getting stronger.
              </h1>
              <p style={{ fontSize: 20 }}>
                <b>Digital Pioneers.</b> Pave the way for a healthy data economy
                in which businesses can increase their engagement and people can
                be rewarded for their contributions.
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel2}
                alt="image of city skyline night illuminated by a faint overlaid globe in the background with nodal architecture"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">
                Sales teams love this easy-to-use feature!
              </h1>
              <p style={{ fontSize: 20 }}>
                <b>Simple Rewards.</b> Reward email responses in any CRM,
                sequence, platform, or anywhere emails can be used. BUT... only
                use this email address when you want ANY recipient to get a
                reward for responding. Only one active rewards campaign at a
                time.
              </p>
            </div>
            <div>
              {' '}
              <img
                className="d-block w-100"
                src={Carousel3}
                alt="image of curvature with 3D asymptopic curvature"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <h1 className="text-center">
                Marketing & product feedback teams use this the most.
              </h1>
              <p style={{ fontSize: 20 }}>
                <b>Scaleable Rewards.</b> Want to A/B test multiple rewards in
                parallel across marketing and single emails? Our integrations
                let you manage multiple campaigns for next-level marketing.
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel4}
                alt="interconnected nodes"
              />
            </div>
          </Carousel.Item>
          {/* Note: This carousel item below is not actually viewed. It is immediately skipped. */}
          <Carousel.Item>
            <div>
              <h1>Marketing & product feedback teams use this the most.</h1>
              <p style={{ fontSize: 20 }}>
                <b>Scaleable Rewards.</b> Want to A/B test multiple rewards in
                parallel across marketing and single emails? Our integrations
                let you manage multiple campaigns for next-level marketing.
              </p>
            </div>
            <div>
              <img
                className="d-block w-100"
                src={Carousel4}
                alt="ending slide"
              />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  };

  return (
    <div>
      {isCustomerInfoFetched === undefined ? (
        <LoadingBar />
      ) : isCustomerInfoFetched !== undefined &&
        isCustomerInfoFetched !== null &&
        isCustomerInfoFetched.emailVerified ? (
        <LoadingBar />
      ) : (
        carouselUI()
      )}
    </div>
  );
};

export default SignInSuccess;
