import React, { Component } from 'react';

class Select extends Component {
  onChange = (event) => {
    const { id, onChange } = this.props;
    const { value } = event.target;
    return onChange(id, value);
  };

  render() {
    const { id, selectText, disableSelectText, options, value } = this.props;
    return (
      <select
        className="select-field"
        onChange={this.onChange}
        id={id}
        value={value}
      >
        {disableSelectText ? (
          ''
        ) : (
          <option value="" key="">
            {selectText}
          </option>
        )}
        {options.map((item) => (
          <option value={item.value} key={item.key}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }
}

export default Select;
