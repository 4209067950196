import { useAuth0 } from '@auth0/auth0-react';
import { default as React, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SignInPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const { state } = useLocation();
  const navigate = useNavigate();
  const scope = process.env.REACT_APP_AUTH0_GMAIL_SCOPE;

  useEffect(() => {
    // this shows up 3rd on Auth0 landing widget
    console.log('loggin in signin js');
    if (!isAuthenticated && !isLoading) {
      // this shows up 4tn on Auth0 landing widget
      console.log('in if loop');

      // consider updating here with default scope null for advanced options
      loginWithRedirect({
        appState: state,
        scope: scope
      });
    } else if (isAuthenticated && user) {
      console.log('in else loop');
      let redirectpath = '/dashboard';
      if (state !== undefined && state.pathname !== undefined)
        redirectpath = state.pathname;

      navigate(redirectpath);
    }
  }, [isAuthenticated, loginWithRedirect, user, state, isLoading, navigate]);

  return <></>;
};

export default SignInPage;
