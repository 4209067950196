import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { createPaymentIntent } from '../../../redux/payment/actions';
import CheckoutForm from './checkoutForm';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);

const StripeWidget = () => {
  const dispatch = useDispatch();
  const paymentState = useSelector((state) => state.Payment);
  const clientSecret = paymentState.clientSecret;

  useEffect(() => {
    dispatch(createPaymentIntent());
  }, []);

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <>
      {paymentState.loading && <Loader />}

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default StripeWidget;
