import classNames from 'classnames';
import React from 'react';
import ReactQuill from 'react-quill';

const QuillEditor = (props) => {
  const { name, value, onChange, error, touched, className } = props;

  return (
    <div className="quill-editor-wrapper">
      <ReactQuill
        className={classNames({ className, invalid: error && touched })}
        name={name}
        value={value}
        onChange={onChange}
      />
      {touched ? <div className="error-feedback">{error}</div> : ''}
    </div>
  );
};

export default QuillEditor;
