import {
  CreditCard,
  HelpCircle,
  Mail,
  MessageSquare,
  Send,
  Sliders,
  UserPlus
} from 'react-feather';

const pagesSection = [
  {
    href: '/dashboard',
    icon: Sliders,
    title: 'Dashboard'
  },
  {
    href: '/campaign',
    icon: MessageSquare,
    title: 'Campaign',
    children: [
      {
        href: '/campaign/create',
        title: 'Create',
        parent: 'Campaign'
      },
      {
        href: '/campaign/integrate',
        title: 'Integrate',
        parent: 'Campaign'
      },
      {
        href: '/campaign/history',
        title: 'History',
        parent: 'Campaign'
      },
      {
        href: '/campaign/transaction',
        title: 'Transactions',
        parent: 'Campaign'
      }
    ]
  },
  {
    href: '/email',
    icon: Mail,
    title: 'Email'
  },
  {
    href: '/wallet',
    icon: CreditCard,
    title: 'Wallet'
  },
  {
    href: '/invite',
    icon: UserPlus,
    title: 'Invite Employee'
  },
  {
    href: '/help',
    icon: HelpCircle,
    title: 'Help'
  }
];

const navItems = [
  {
    pages: pagesSection
  }
];

export default navItems;
