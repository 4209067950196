export const getRandomBetweenRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const convertCentsToUSD = (amountInCents) => {
  return parseFloat(parseInt(amountInCents) / 100);
};

// shows console logging messages only in development environments.
export const devLog = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};
