import React, { useState } from 'react';
import StripeWidget from './stripeWidget';
import Stepper from 'react-stepper-js';
import 'react-stepper-js/dist/index.css';
import { Info } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ChevronsRight } from 'react-feather';
import { CheckCircle } from 'react-feather';
import {
  clearPaymentIntent,
  updatePaymentAmount
} from '../../../redux/payment/actions';

import { Form } from 'react-bootstrap';
import { useEffect } from 'react';

function PaymentDetails(props) {
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const status = query.get('redirect_status');
    if (status == 'succeeded') {
      setActiveStep(3);
      dispatch(clearPaymentIntent());
    } else {
      setActiveStep(1);
    }
  }, []);

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
    setError(undefined);
    if (parseFloat(e.target.value) < 5) {
      setError('Payment amount should be greater than or equal to $5');
    }
  };

  const getStep = (step) => {
    switch (step) {
      case 1:
        return AddFundUi();
        break;
      case 2:
        return <StripeWidget />;
        break;
      case 3:
        return SuccessScreen();
        break;
    }
  };

  const handleAddFunds = () => {
    if (
      paymentAmount === undefined ||
      paymentAmount === '' ||
      parseFloat(paymentAmount) < 5
    ) {
      setError('Payment amount should be greater than or equal to $5');
    } else {
      dispatch(updatePaymentAmount(paymentAmount));
      setActiveStep(activeStep + 1);
    }
  };

  const AddFundUi = () => {
    return (
      <div className="my-5">
        <div className="w-75 my-5">
          <h1 className="text-white">Add Fund</h1>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={paymentAmount}
              isInvalid={!!error}
              onChange={handlePaymentAmountChange}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="w-100 my-5">
          <span
            onClick={handleAddFunds}
            disabled={!!error}
            className="next-btn"
          >
            Next
            <ChevronsRight />
          </span>
        </div>
      </div>
    );
  };

  const SuccessScreen = () => {
    return (
      <div className="py-5">
        <div className="d-flex justify-content-center my-4">
          <i
            className="fa fa-check-circle-o bigger-size"
            aria-hidden="true"
          ></i>
          <CheckCircle size="150" className="text-white" />
        </div>
        <div className="">
          <div className="bottom-container">
            <h1 className="text-center text-white">You did it!</h1>
            <h3 className="text-center text-warning py-5">
              Your Wallet has been Recharged Successfully...
            </h3>
            <div className="d-flex justify-content-center pb-3">
              <button
                className="btn btn-lg btn-warning"
                onClick={() => {
                  navigate('/wallet');
                }}
              >
                Go back to your wallet...
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div>
        <Stepper
          color="#23b561"
          fontSize="20px"
          fontColor="#18aed6"
          steps={[
            { label: 'Add Funds' },
            { label: 'Add Card Details' },
            { label: 'Success' }
          ]}
          currentStep={activeStep}
        />
      </div>
      <div>
        <div className="d-flex justify-content-center">
          <div className="add-payment-outer-panel">
            <div className="card add-fund-bg">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <div className="d-inline">{getStep(activeStep)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;
