import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import NotyfContext from '../../../contexts/NotyfContext';

const CheckoutForm = () => {
  const notyf = useContext(NotyfContext);
  const stripe = useStripe();
  const elements = useElements();
  const paymentState = useSelector((state) => state.Payment);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const baseURL = window.location.origin;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${baseURL}/wallet/payment-details`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      notyf.open({ type: 'error', message: error.message });
    } else {
      notyf.open({ type: 'error', message: 'An unexpected error occured.' });
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="my-5">
        <PaymentElement id="payment-element" />
        <div className="mt-3">
          <div className="payment-amount__label">
            Payment Amount:{' '}
            <strong className="text-white">
              ${paymentState.paymentAmount}
            </strong>
          </div>
        </div>
        <div className="horizontal-center mt-3">
          <Button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            onClick={handleSubmit}
          >
            <span id="button-text">
              {isLoading ? (
                <span>
                  Processing..{'  '}
                  <Spinner animation="border" size="sm" className="me-2" />
                </span>
              ) : (
                'Pay Now'
              )}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
