import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import fynComLogo from '../../../assets/img/logo.png';
import { ApiRoutes } from '../../../constants/api-routes';
import NotyfContext from '../../../contexts/NotyfContext';

function Invitation() {
  const queryParams = new URLSearchParams(window.location.search);
  const notyf = useContext(NotyfContext);
  const [invitation, setInvitations] = useState();

  useEffect(() => {
    async function getInviteDetails() {
      const options = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      let apiUrl =
        process.env.REACT_APP_SERVER_BASE_V2_URL +
        ApiRoutes.invitationStatusUrl(queryParams.get('code'));
      return axios
        .get(apiUrl, options)
        .then((response) => {
          setInvitations(response.data);
        })
        .catch((error) => {
          notyf.open({
            type: 'error',
            message: error
          });
        });
    }
    getInviteDetails();
  }, []);

  const handleInvitationResponse = (isAccepted) => {
    let requestData = {};
    requestData['orgInviteId'] = queryParams.get('code');
    requestData['status'] = isAccepted ? 'ACCEPTED' : 'DECLINED';
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const apiUrl =
      process.env.REACT_APP_SERVER_BASE_V2_URL +
      ApiRoutes.handleInvitationUrl();
    return axios
      .post(apiUrl, JSON.stringify(requestData), options)
      .then((response) => {
        if (isAccepted) {
          window.location.replace(window.location.origin);
        } else {
          window.location = 'https://www.fyncom.com';
        }
      })
      .catch((error) => {
        notyf.open({
          type: 'error',
          message: error.response.data.message
        });
      });
  };

  return (
    <div className="h-100 w-100">
      <div className="w-100 d-flex justify-content-center h-100">
        <Card text="" className="mx-4 invitation_card">
          <Card.Header className="invitation_card_header">
            <div className="d-flex justify-content-center h-100">
              <img src={fynComLogo} className="invitation_logo_img py-2" />
            </div>
          </Card.Header>
          <Card.Body className="invitation_card_body_outer">
            <div className="invitation_card_body">
              <div className="text-center">
                {invitation && invitation.inviteStatus === 'ACCEPTED' ? (
                  <div>
                    <div>
                      Your invitation is already Accepted .Please click here to
                      login to your account
                    </div>
                    <div className="mt-3 horizontal-center">
                      <Button
                        variant="primary"
                        className="mx-1"
                        onClick={() => {
                          window.location = 'https://www.fyncom.com';
                        }}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                ) : invitation && invitation.inviteStatus === 'INVITED' ? (
                  <div>
                    <div>Accept the invite to join the organization</div>
                    <div className="mt-3 horizontal-center">
                      <Button
                        variant="secondary"
                        className="mx-1"
                        onClick={() => {
                          handleInvitationResponse(false);
                        }}
                      >
                        Decline
                      </Button>

                      <Button
                        variant="primary"
                        className="mx-1"
                        onClick={() => {
                          handleInvitationResponse(true);
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  </div>
                ) : invitation && invitation.inviteStatus === 'DECLINED' ? (
                  <div>This invitation has already been Declined</div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Invitation;
