import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logoImg from '../../assets/img/logo_circle.png';
import useSidebar from '../../hooks/useSidebar';
import SidebarNav from './SidebarNav';

const Sidebar = ({ items }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img
              src={logoImg}
              alt="logo"
              height="30px"
              style={{ marginRight: '8px' }}
            />
            <span className="align-middle me-3">FynCom</span>
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
