import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Info, DollarSign } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import NotyfContext from '../../contexts/NotyfContext';
import { getOrganizationInfo } from '../../redux/customer/actions';
import { clearPaymentIntent } from '../../redux/payment/actions';
import { convertCentsToUSD } from '../../utils/common';
import * as Api from '../../utils/api';
import DataTable from '../../components/datatable';
import { ApiRoutes } from '../../constants/api-routes';
import { DateCellRenderer } from '../../utils/datatable';
import { VerifiyEmailUI } from '../../components/tutorModule/commonuiutils';

const TransactionHistory = ({ transactionHistory }) => {
  const initialSort = [
    {
      id: 'name',
      desc: true
    }
  ];
  const columns = React.useMemo(() => [
    {
      Header: 'Amount ($)',
      accessor: 'paymentAmount',
      Cell: ({ value }) => {
        if (value != 0) {
          return (parseInt(value) / 100).toFixed(2);
        } else {
          return 0;
        }
      }
    },
    {
      Header: 'Amount Received',
      accessor: 'paymentAmountReceived',
      Cell: ({ value }) => {
        if (value != 0) {
          return (parseInt(value) / 100).toFixed(2);
        } else {
          return 0;
        }
      }
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus'
    },
    {
      Header: 'Transaction ID',
      accessor: 'transactionId'
    },
    {
      Header: 'Create Date',
      accessor: 'createDate',
      Cell: ({ value }) => DateCellRenderer(value)
    },
    {
      Header: 'Update Date',
      accessor: 'updateDate',
      Cell: ({ value }) => DateCellRenderer(value)
    }
  ]);
  return (
    <div>
      <DataTable
        columns={columns}
        data={transactionHistory}
        sortBy={initialSort}
      />
    </div>
  );
};

const Wallet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const paymentState = useSelector((state) => state.Payment);
  const customerState = useSelector((state) => state.Customer);
  const organization = customerState.info.organizations[0];
  const [transactionHistory, setTransactionHistory] = useState();

  useEffect(() => {
    if (customerState.info.emailVerified) {
      const { organizationId } = customerState.info.organizations[0];
      async function getStripeTransactionHistory() {
        try {
          let stripTransactionHistoryResponse = await Api.getData(
            ApiRoutes.stripeTransactionHistoryUrl(organizationId)
          );
          setTransactionHistory(
            stripTransactionHistoryResponse.stripeTransactions
          );
        } catch (error) {
          notify.open({
            type: 'error',
            message: 'failed to fetch transaction History'
          });
        }
      }

      const query = new URLSearchParams(window.location.search);
      const intentId = query.get('payment_intent');
      const clientSecret = query.get('payment_intent_client_secret');
      const status = query.get('redirect_status');
      getStripeTransactionHistory();
      dispatch(getOrganizationInfo());

      if (
        intentId &&
        clientSecret &&
        intentId === paymentState.intentId &&
        clientSecret === paymentState.clientSecret
      ) {
        dispatch(clearPaymentIntent());
        if (status === 'succeeded') {
          notyf.open({
            type: 'success',
            message: 'Amount transferred successfully'
          });
        }
      }
    }
  }, []);

  const handleShow = () => {
    navigate('/wallet/payment-details');
  };

  return (
    <React.Fragment>
      <VerifiyEmailUI />
      <Helmet title="Wallet" />
      <Container fluid className="p-0 my-3">
        <Card>
          <Card.Header>
            <Row>
              <div className="col-6">
                <h1>Wallet</h1>
              </div>
              <div className="col-6">
                <Button
                  className="btn btn--primary float-end add-funds-btn"
                  onClick={() => handleShow()}
                  color="primary"
                  id="create-campaign__button"
                  disabled={
                    !(customerState && customerState.info.emailVerified)
                  }
                >
                  Add Funds
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-help">
                        This will go into your FynCom balance. Parts of your
                        FynCom balance only gets converted to nano once you
                        create your campaigns.
                      </Tooltip>
                    }
                  >
                    <Info size="15" className="recharge-info__icon" />
                  </OverlayTrigger>
                </Button>
              </div>
            </Row>
          </Card.Header>
        </Card>
      </Container>
      <Container fluid className="p-0">
        <Card>
          <Card.Header>
            <div>
              <Row>
                <Col sm={12} md={4} lg={4} xl={4} xs={12}>
                  <Row>
                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <DollarSign className="align-middle" />
                      </div>
                    </Col>
                    <Col className="mt-0">
                      <h5 className="card-title">
                        Available Fiat Balance (USD)
                      </h5>
                      <span className="h1 d-inline-block mt-1 mb-3">
                        $
                        {organization &&
                        organization.availableFiatBalanceInCents
                          ? convertCentsToUSD(
                              organization.availableFiatBalanceInCents
                            )
                          : 0}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={4} lg={4} xl={4} xs={12}>
                  <Row>
                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <DollarSign className="align-middle" />
                      </div>
                    </Col>
                    <Col className="mt-0">
                      <h5 className="card-title">
                        Available Promotional Balance (USD)
                      </h5>

                      <span className="h1 d-inline-block mt-1 mb-3">
                        $
                        {convertCentsToUSD(
                          organization.availablePromotionalBalanceInCents
                        )}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={4} lg={4} xl={4} xs={12}>
                  <Row>
                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <DollarSign className="align-middle" />
                      </div>
                    </Col>
                    <Col className="mt-0">
                      <h5 className="card-title">
                        Available Rewards Balance (USD)
                      </h5>

                      <span className="h1 d-inline-block mt-1 mb-3">
                        $
                        {convertCentsToUSD(
                          organization.availableCryptoBalanceInCents
                        )}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card.Header>
          <Card.Body>
            <TransactionHistory
              transactionHistory={transactionHistory ? transactionHistory : []}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Wallet;
