import React, { useState } from 'react';
import { Col, Container, ListGroup, Row, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import EmailProvider from './emailProvider';
import Organizations from './organizations';
import Integration from './integrations';
import Subscription from './subscription';
import Preference from './preferences';

function Settings() {
  const [selectIndex, setNavIndex] = useState('1');

  const Navigation = () => (
    <Card>
      <ListGroup variant="flush">
        {/* todo set up Clarity on Hex colors */}
        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === '1'}
          onClick={() => {
            setNavIndex('1');
          }}
        >
          Organization
        </ListGroup.Item>
        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === '5'}
          onClick={() => {
            setNavIndex('5');
          }}
        >
          Preferences
        </ListGroup.Item>

        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === '2'}
          onClick={() => {
            setNavIndex('2');
          }}
        >
          Email Provider
        </ListGroup.Item>

        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === '3'}
          onClick={() => {
            setNavIndex('3');
          }}
        >
          Integration
        </ListGroup.Item>
        <ListGroup.Item
          tag="button"
          action
          active={selectIndex && selectIndex === '4'}
          onClick={() => {
            setNavIndex('4');
          }}
        >
          Subscription
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <Card>
          <Card.Header>
            <h1>Organization Settings</h1>
          </Card.Header>
        </Card>
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            {selectIndex == '1' ? <Organizations /> : ''}
            {selectIndex == '2' ? <EmailProvider /> : ''}
            {selectIndex == '3' ? <Integration /> : ''}
            {selectIndex == '4' ? <Subscription /> : ''}
            {selectIndex == '5' ? <Preference /> : ''}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Settings;
