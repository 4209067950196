import React from 'react';
import { Pagination } from 'react-bootstrap';
import Select from '../base/select';

const pageSizeOptions = [
  { key: 10, value: 10, name: '10' },
  { key: 20, value: 20, name: '20' },
  { key: 30, value: 30, name: '30' },
  { key: 40, value: 40, name: '40' },
  { key: 50, value: 50, name: '50' }
];

const separate = (a, b) => {
  if (a - b == 0) {
    return [a];
  } else if (a - b == 1) {
    return [a, b];
  } else {
    return [a, '...', b];
  }
};

const pagination = (currentPage, pageCount, delta = 2) => {
  return Array(delta * 2 + 1)
    .fill(0)
    .map((_, index) => currentPage - delta + index)
    .filter((page) => page > 0 && page <= pageCount)
    .flatMap((page, index, { length }) => {
      if (!index) return separate(1, page);
      if (index === length - 1) return separate(page, pageCount);

      return [page];
    });
};

const PaginationComp = (props) => {
  const {
    pageIndex,
    pageOptions,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    pageSize,
    setPageSize
  } = props;
  const totalPages = pageOptions.length;
  const currentPage = pageIndex + 1;
  const pages = pagination(currentPage, totalPages);
  return (
    <div className="pagination-wrapper">
      <div className="pagination__num-entries">
        <div>Show</div>
        <div>
          <Select
            id="table-page-select"
            options={pageSizeOptions}
            value={pageSize}
            onChange={(id, value) => {
              setPageSize(Number(value));
            }}
            disableSelectText
          />
        </div>
        <div>entries</div>
      </div>

      <div className="pagination-details">{`Showing ${currentPage} of ${totalPages} pages`}</div>
      <div className="pagination-action">
        <Pagination className="float-end">
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default PaginationComp;
