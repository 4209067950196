import React from 'react';
import { useLocation } from 'react-router-dom';
import reduceChildRoutes from './reduceChildRoutes';

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  // console.log('SidebarNavListItem props');
  // console.log(props);

  const childRoutes = pages.reduce(
    (items, page, sidebarIndex) =>
      reduceChildRoutes({ items, page, currentRoute, depth, sidebarIndex }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
