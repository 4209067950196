import React from 'react';
import { Outlet } from 'react-router-dom';
import Content from '../components/Content';
import Footer from '../components/Footer';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import dashboardItems from '../components/sidebar/dashboardItems';
import Sidebar from '../components/sidebar/Sidebar';
import Wrapper from '../components/Wrapper';
import TutorModule from '../components/tutorModule';

const Dashboard = ({ children }) => {
  // const TutorContext = React.createContext('tutor');

  return (
    <React.Fragment>
      <TutorModule />
      <div id="main"></div>
      <Wrapper>
        <Sidebar items={dashboardItems} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
