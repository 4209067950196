import { PaymentTypes } from './types';

export const updatePaymentAmount = (paymentAmount) => {
  return {
    type: PaymentTypes.UPDATE_PAYMENT_AMOUNT,
    payload: { paymentAmount }
  };
};

export const createPaymentIntent = () => {
  return {
    type: PaymentTypes.CREATE_PAYMENT_INTENT
  };
};

export const createPaymentIntentSuccess = (intentId, clientSecret) => {
  return {
    type: PaymentTypes.CREATE_PAYMENT_INTENT_SUCCESS,
    payload: { intentId, clientSecret }
  };
};

export const updatePaymentIntent = () => {
  return {
    type: PaymentTypes.UPDATE_PAYMENT_INTENT
  };
};

export const updatePaymentIntentSuccess = (intentId, clientSecret) => {
  return {
    type: PaymentTypes.UPDATE_PAYMENT_INTENT_SUCCESS,
    payload: { intentId, clientSecret }
  };
};

export const clearPaymentIntent = () => {
  return {
    type: PaymentTypes.CLEAR_PAYMENT_INTENT
  };
};
