/************ CUSTOMER ROUTES ******************/
const getCustomerAccountInfoRoute = (auth0Id) =>
  `/customer/${auth0Id}/account-info`;
const updateCustomerPreference = () => '/preference/update';
const getCustomerPreferenceRoute = (auth0Id) => `/preference/${auth0Id}`;
/*** @todo /preference/${id} is not a valid endpoint on our backend. Please update to use updateCustomerPreference ***/
const userPreference = (id) => `/preference/${id}`;

/************ ORAGANIZATION ROUTES ******************/
const createOrganizationRoute = () => '/organization/create';
const updateOrganizationRoute = () => '/organization/update';
const updateOrganizationPreferenceRoute = () =>
  '/preference/organization/update';
const getOrganizationInfoRoute = (organizationId) =>
  `/organization/${organizationId}`;

/************ INVITE EMPLOYEE / CUSTOMER ROUTES ******************/
const invitesUrls = (id) => `/employee/invite/${id}`;
const handleInvitationUrl = () => '/employee/invite/handle';
const invitationStatusUrl = (id) => `/employee/invite/${id}`;
const getCreateInvitationUrl = () => '/employee/invite/create';

/************ CAMPAIGN ROUTES ******************/
const createCampaignRoute = () => '/campaign/create';
const getAllCampaignsRoutes = (customerId) => `/campaign/all/${customerId}`;
const getCampaignTransactions = (id) => `/campaign/reward/transactions/${id}`;

/************ DATA ANALYTICS / CAMPAIGN REWARD HISTORY ROUTES ******************/
const avgRewardPerResponseUrl = (id) => `/average/reward/${id}`;
const transactionAvgReward = () => '/average/reward/';

/************ EMAIL ROUTES ******************/
const sendEmailRoute = () => '/email/send';
const sendTemplateEmailRoute = () => '/email/send/template';
const getSendersURL = (id) => `/customer/${id}/sendgrid-senders`;
const refreshSendersURL = (id) => `/customer/${id}/sendgrid-senders/refresh`;
const getMarketingDraftTemplates = (organizationId) =>
  `/email/${organizationId}/sendgrid/drafts`;
const getMarketingTemplateDetail = (organizationId, templateId) =>
  `/email/${organizationId}/sendgrid/drafts/${templateId}`;
const getKDEmailMarket = () => '/email/send/template/kubikdata';
const getKDEmailBasic = () => '/email/send/survey';
const sendExampleRewards = (auth0Id, numberOfSends) =>
  `/email/send/rewards/example/${auth0Id}/${numberOfSends}`;

/************ API KEYS / CREDENTIALS ROUTES ******************/
const getApiCredentials = (serviceName) => `/api/${serviceName}`;
const setApiCredentials = () => `/api/key/create`;
const addMailProviderKeyRoute = () => '/api/key/create';

/************ PAYMENT ROUTES ******************/
const createPaymentIntentRoute = () => '/payment/create-payment-intent';
const updatePaymentIntentRoute = () => '/payment/update-payment-intent';
const stripeTransactionHistoryUrl = (id) => `/stripe/transactions/${id}`;

/************ Image Upload ******************/
const uploadImageUrl = () => '/upload/file';

/************ TEXT ROUTES ******************/
const sendFirstTextFynCallRoute = () => '/text/fyntext';

export const ApiRoutes = {
  getCustomerAccountInfoRoute,
  createOrganizationRoute,
  getOrganizationInfoRoute,
  createCampaignRoute,
  getAllCampaignsRoutes,
  invitesUrls,
  getCreateInvitationUrl,
  sendEmailRoute,
  sendTemplateEmailRoute,
  getApiCredentials,
  setApiCredentials,
  getMarketingDraftTemplates,
  getMarketingTemplateDetail,
  updateOrganizationRoute,
  updateOrganizationPreferenceRoute,
  createPaymentIntentRoute,
  updatePaymentIntentRoute,
  invitesUrls,
  getCreateInvitationUrl,
  addMailProviderKeyRoute,
  getSendersURL,
  refreshSendersURL,
  handleInvitationUrl,
  avgRewardPerResponseUrl,
  getCampaignTransactions,
  invitationStatusUrl,
  avgRewardPerResponseUrl,
  transactionAvgReward,
  stripeTransactionHistoryUrl,
  uploadImageUrl,
  updateCustomerPreference,
  getCustomerPreferenceRoute,
  /**@todo userPreference is not a valid endpoint. Please remove */
  userPreference,
  getKDEmailMarket,
  getKDEmailBasic,
  sendExampleRewards,
  sendFirstTextFynCallRoute
};
