import React, { useEffect } from 'react';
import DataTable from '../../components/datatable/index';
import { Card } from 'react-bootstrap';
import { useState, useContext } from 'react';
import { getData } from '../../utils/api';
import { useSelector } from 'react-redux';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { DateCellRenderer } from '../../utils/datatable';

function CampaignsTransaction() {
  const [transactions, setTransactions] = useState();
  const customerState = useSelector((state) => state.Customer);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if (customerState.info.emailVerified) {
      async function getCompaignTransactions() {
        try {
          let response = await getData(
            ApiRoutes.getCampaignTransactions(customerState.info.auth0Id)
          );
          setTransactions(response.campaignRewardTransactions);
        } catch (error) {
          notyf.open({
            type: 'error',
            message: error
          });
        }
      }
      getCompaignTransactions();
    }
  }, []);

  const initialSort = [
    {
      id: 'nanoAmount',
      desc: true
    }
  ];
  const columns = React.useMemo(() => [
    {
      Header: 'Campaign Name',
      accessor: 'campaignName',
      Cell: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1)
    },
    {
      Header: 'Amount ($)',
      accessor: 'equivalentUSDAmount'
    },
    {
      Header: 'Campaign Owner (Email)',
      accessor: 'campaignOwnerEmailId'
    },
    {
      Header: 'Email Sender',
      accessor: 'senderEmailId'
    },
    {
      Header: 'Email Recepient',
      accessor: 'receiverUserEmailId'
    },
    {
      Header: 'Email Sent Date',
      accessor: 'emailSentDate',
      Cell: ({ value }) => DateCellRenderer(value)
    },
    {
      Header: 'Response Received Date',
      accessor: 'rewardCreateDate',
      Cell: ({ value }) => DateCellRenderer(value)
    }
  ]);

  return (
    <div>
      <Card id="transaction-id">
        <Card.Header>
          <h1>Transactions</h1>
        </Card.Header>
      </Card>
      <Card>
        <Card.Body>
          <DataTable
            columns={columns}
            data={transactions ? transactions : []}
            sortBy={initialSort}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default CampaignsTransaction;
