import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Info } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { ApiRoutes } from '../../../constants/api-routes';
import NotyfContext from '../../../contexts/NotyfContext';
import { postData, getData } from '../../../utils/api';
import { VerifiyEmailUI } from '../../../components/tutorModule/commonuiutils';
import { setStepCount } from '../../../redux/tutor/actions';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Campaign name cannot be empty'),
  budget: Yup.number()
    .required('Campaign budget is required')
    .positive('Budget must be greater than 0'),
  reward: Yup.number()
    .required('Campaign reward cannot be empty')
    .positive('Reward must be greater than 0'),
  campaignType: Yup.boolean()
});

const initialCampaignValues = {
  name: '',
  description: '',
  reason: '',
  budget: 0,
  reward: 0,
  campaignType: false
};

const Create = () => {
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const customerState = useSelector((state) => state.Customer);
  const tutorState = useSelector((state) => state.Tutor);
  const dispatch = useDispatch();
  // A number of variables are set to allow for suggested terms of 1st rewards campaign
  const [firstCampaign, setFirstCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState('My First Rewards Campaign');
  const [campaignReward, setCampaignReward] = useState(0.001);
  const [campaignBudget, setCampaignBudget] = useState(0.1);
  const [campaignDesc, setCampaignDesc] = useState(
    'My first rewards campaign. I will practice on my own email first. After that, I will be able to edit these fields and make my own rewards campaigns with any value. Values from $0.0000000001 to $1,000 may be used per reward.'
  );
  const [campaignReason, setCampaignReason] = useState(
    'This will be publicly viewable to the recipient of my reward. It lets them know why they got the reward. I should put something nice or descriptive here.'
  );

  useEffect(() => {
    if (error) notyf.open({ type: 'error', message: error });

    if (success) notyf.open({ type: 'success', message: success });
  }, [error, success, notyf]);

  /**
   * This detects whether this is the customer's 1st rewards campaign or not
   */
  useEffect(() => {
    if (customerState.info.emailVerified) {
      const fetchCampaigns = async () => {
        try {
          setLoading(true);
          const getCampaignResponse = await getData(
            ApiRoutes.getAllCampaignsRoutes(customerState.info.customerId)
          );

          if (getCampaignResponse.campaigns.length == 0) {
            console.log('Welcome!');
            setFirstCampaign(true);
          } else {
            console.log('Welcome back!');
            // TODO this does not properly reset the form after a person does their 1st campaign....
            setFirstCampaign(false);
            setCampaignName('Enter campaign name');
            setCampaignDesc('Enter campaign description');
            setCampaignReason('Enter campaign reason');
          }
        } catch (err) {
          console.log('failed to get all campaigns');
        } finally {
          setLoading(false);
        }
      };

      fetchCampaigns();
    } else {
      // customer has not verified their email yet.
      setFirstCampaign(true);
    }
  }, [campaignName]);

  const handleCreateCampaign = (values, actions) => {
    actions.setSubmitting(true);
    const { name, description, reason, budget, reward } = values;

    const createCampaignRequest = {
      customerId: customerState.info.customerId,
      description,
      reason,
      budget,
      amountUser: reward
    };
    createCampaignRequest.name = values.campaignType ? `ZAPIER-${name}` : name;

    setLoading(true);
    setError(null);
    setSuccess(null);
    postData(ApiRoutes.createCampaignRoute(), createCampaignRequest)
      .then((res) => {
        setLoading(false);

        if (firstCampaign) {
          setSuccess(
            'Whoo! 1st Campaign created! Now go to the Email tab and send yourself an email + respond to that email to test your rewards'
          );
          setFirstCampaign(false);
          // TODO This doesn't seem to have the intended effect. After 1st campaign, the values stick around....
          setCampaignName('Enter campaign name');
          setCampaignDesc('Enter campaign description');
          setCampaignReason('Enter campaign reason');
        } else {
          setSuccess('Campaign created successfully');
        }
        actions.resetForm(); //note: this is annoying. Consider adding a button to resuse last campaign details
        if (tutorState.isActive && tutorState.tutorStep == 6) {
          dispatch(setStepCount(tutorState.tutorStep + 1));
        }
      })
      .catch((err) => {
        if (err.status === 422) {
          setError('Your Wallet balance is too low. Please add funds.');
        } else if (err.status === 412) {
          setError(
            'The "Reward per Response" cannot be higher than the Campaign budget. Please try again.'
          );
        } else if (err.status === 404) {
          setError(
            'Whoops! Something is wrong with your account. Please contact support@fyncom.com'
          );
        } else {
          setError('Unable to create Campaign. Please try again');
          console.log('other error');
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <VerifiyEmailUI />
      <Helmet title="Create Campaign" />
      <Container fluid className="p-0">
        <Col lg="10">
          <div className="position-relative">
            <Card className="py-1 pl-2">
              <Card.Body>
                <Row>
                  <div className="col-6">
                    <h1>Create Rewards Campaign</h1>
                  </div>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Row>
          <Col lg="10">
            <div className="position-relative">
              <div className="create-campaign-from-container">
                <Card>
                  <Card.Body>
                    {/* todo detect if email has not yet been verified 
                    and update the message content with that as a 1st step if so */}
                    {firstCampaign === true ? (
                      <div className="alert alert-info">
                        Your first campaign defaults to the text you see in the
                        boxes. First, create this campaign as-is. Then, we
                        suggest you try a test reward with it. However, right
                        after you create this 1st default campaign, you can
                        create your next real campaigns and proceed.
                      </div>
                    ) : null}
                    {loading && <Loader />}
                    <Formik
                      initialValues={initialCampaignValues}
                      validationSchema={ValidationSchema}
                      onSubmit={(values, actions) =>
                        handleCreateCampaign(values, actions)
                      }
                    >
                      {(formikProps) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting
                        } = formikProps;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                              <Form.Label>Name</Form.Label>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-help">
                                    Name is an internal reference for your team.
                                    Customers will not see this.
                                  </Tooltip>
                                }
                              >
                                <Info
                                  size="15"
                                  className="recharge-info__icon"
                                />
                              </OverlayTrigger>
                              <Form.Control
                                id="name"
                                type="text"
                                name="name"
                                isInvalid={
                                  firstCampaign === true
                                    ? false
                                    : touched.name && !!errors.name
                                }
                                value={
                                  firstCampaign === true
                                    ? (values.name = campaignName)
                                    : values.name
                                }
                                placeholder={campaignName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form_control'}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Description</Form.Label>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-help">
                                    Description of campaign is an internal
                                    reference for your team. Customers will not
                                    see this.
                                  </Tooltip>
                                }
                              >
                                <Info
                                  size="15"
                                  className="recharge-info__icon"
                                />
                              </OverlayTrigger>
                              <Form.Control
                                as="textarea"
                                name="description"
                                value={
                                  firstCampaign === true
                                    ? (values.description = campaignDesc)
                                    : values.description
                                }
                                placeholder={campaignDesc}
                                rows="2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form_control'}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Reason</Form.Label>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-help">
                                    After completing the action, your audience
                                    will see this text in a follow up email from
                                    FynCom. Say something they'll understand
                                    like "responding to our email" or "answering
                                    our Typeform".
                                  </Tooltip>
                                }
                              >
                                <Info
                                  size="15"
                                  className="recharge-info__icon"
                                />
                              </OverlayTrigger>
                              <Form.Control
                                name="reason"
                                type="text"
                                value={
                                  firstCampaign === true
                                    ? (values.reason = campaignReason)
                                    : values.reason
                                }
                                placeholder={campaignReason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form_control'}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Budget</Form.Label>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-help">
                                    Budget is your limit for your campaign.
                                  </Tooltip>
                                }
                              >
                                <Info
                                  size="15"
                                  className="recharge-info__icon"
                                />
                              </OverlayTrigger>
                              <Form.Control
                                type="number"
                                name="budget"
                                value={
                                  firstCampaign === true
                                    ? (values.budget = campaignBudget)
                                    : values.budget
                                }
                                placeholder={campaignBudget}
                                isInvalid={
                                  firstCampaign === true
                                    ? false
                                    : touched.budget && !!errors.budget
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form_control'}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.budget}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Reward per Response</Form.Label>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-help">
                                    The amount people get for responding.
                                  </Tooltip>
                                }
                              >
                                <Info
                                  size="15"
                                  className="recharge-info__icon"
                                />
                              </OverlayTrigger>
                              <Form.Control
                                type="number"
                                name="reward"
                                value={
                                  firstCampaign === true
                                    ? (values.reward = campaignReward)
                                    : values.reward
                                }
                                placeholder={campaignReward}
                                isInvalid={
                                  firstCampaign === true
                                    ? false
                                    : touched.reward && !!errors.reward
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form_control'}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.reward}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input zapier-check-box_css"
                                  type="checkbox"
                                  onClick={(e) => {
                                    formikProps.setFieldValue(
                                      'campaignType',
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label zapier-check-box-label"
                                  for="flexSwitchCheckChecked"
                                >
                                  Enable Zapier
                                </label>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="tooltip-help">
                                      Please enable if this is for Zapier.
                                      Please do NOT include Zapier keywords in
                                      your campaign name. That is handled by
                                      enabling this field.
                                    </Tooltip>
                                  }
                                >
                                  <Info
                                    size="15"
                                    className="recharge-info__icon"
                                  />
                                </OverlayTrigger>
                              </div>

                              <Form.Control.Feedback type="invalid">
                                {errors.reward}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <div className="horizontal-center">
                              <div className="d-inline" id="create_btn">
                                <Button
                                  variant="primary"
                                  className="form-btn"
                                  disabled={isSubmitting}
                                  type="submit"
                                  disabled={
                                    !(
                                      customerState &&
                                      customerState.info.emailVerified
                                    )
                                  }
                                >
                                  Create
                                </Button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Create;
