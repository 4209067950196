import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Settings, User } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Avtar from '../../assets/img/avatar.png';

const NavbarUser = () => {
  const authState = useSelector((state) => state.Auth);
  const customerState = useSelector((state) => state.Customer);
  const userPrefState = useSelector((state) => state.Auth.userPref);
  const { user } = authState;
  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate('/auth/signout');
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={
              userPrefState && userPrefState.profilePicture
                ? userPrefState.profilePicture
                : Avtar
            }
            className="avatar img-fluid rounded-circle me-1"
            alt="Profile picture"
          />
          <span className="text-dark">{user.given_name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate('/profile')}>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => navigate('/settings')}>
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            window.location = 'https://www.fyncom.com/help-center';
          }}
        >
          Help
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
