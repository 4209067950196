import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import NotyfContext from '../../contexts/NotyfContext';
import axios from 'axios';
import 'github-markdown-css';
import { marked } from 'marked';

function Integrations() {
  const notyf = useContext(NotyfContext);
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(
          'https://raw.githubusercontent.com/fyncom/fyncom-help/main/Overview-Of-FynCom-Tools.md'
        );
        setMarkdownContent(marked(response.data));
      } catch (error) {
        notyf.open({
          type: 'error',
          message: 'Failed to load markdown content'
        });
      }
    };

    fetchMarkdown();
  }, []);

  return (
    <div>
      <Card id="transaction-id">
        <Card.Header>
          <h1>Integrate</h1>
        </Card.Header>
      </Card>
      <Card>
        <Card.Body>
          The 1st section of this page shows one of the easiest way to get
          started with FynCom Rewards, by using Zapier to connect to your tools!
          In the 2nd section, you'll see some of our favorite recipe. A full
          list of FynCom products and "how tos" are viewable towards the bottom
          of this page.
        </Card.Body>
        <Card.Body>
          <zapier-full-experience
            client-id="wsb08BQLWMMMVniAZorP07G5MtHdj7HihihyXpaJ"
            theme="auto"
            app-search-bar-display="show"
            app-categories="-accounting,-spreadsheets"
            zap-style="card"
          />
        </Card.Body>
        <Card.Body>
          <div>
            <div
              className="markdown-body"
              dangerouslySetInnerHTML={{ __html: markdownContent }}
            ></div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Integrations;
