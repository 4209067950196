import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ApiRoutes } from '../../constants/api-routes';
import { postData } from '../../utils/api';
import {
  createPaymentIntentSuccess,
  updatePaymentIntentSuccess
} from './actions';
import { PaymentTypes } from './types';

function* createPaymentIntentSaga() {
  const state = yield select();
  const paymentState = state.Payment;
  const customerState = state.Customer;
  try {
    const paymentAmountInCents = parseFloat(paymentState.paymentAmount) * 100;
    const requestData = {
      organizationId: customerState.info.organizations[0].organizationId,
      paymentAmount: paymentAmountInCents
    };
    const response = yield call(
      postData,
      ApiRoutes.createPaymentIntentRoute(),
      requestData
    );
    yield put(
      createPaymentIntentSuccess(response.intentId, response.clientSecret)
    );
  } catch (error) {
    console.log('unable to create paymentIntent: %o', error);
  }
}

function* updatePaymentIntentSaga() {
  const state = yield select();
  const paymentState = state.Payment;

  try {
    const paymentAmountInCents = parseFloat(paymentState.paymentAmount) * 100;
    const requestData = {
      intentId: paymentState.intentId,
      paymentAmount: paymentAmountInCents
    };
    const response = yield call(
      postData,
      ApiRoutes.updatePaymentIntentRoute(),
      requestData
    );
    yield put(
      updatePaymentIntentSuccess(response.intentId, response.clientSecret)
    );
  } catch (error) {
    console.log('unable to update paymentIntent: %o', error);
  }
}

function* watchCreatePaymentIntent() {
  yield takeEvery(PaymentTypes.CREATE_PAYMENT_INTENT, createPaymentIntentSaga);
}

function* watchUpdatePaymentIntent() {
  yield takeEvery(PaymentTypes.UPDATE_PAYMENT_INTENT, updatePaymentIntentSaga);
}

function* paymentSaga() {
  yield all([fork(watchCreatePaymentIntent), fork(watchUpdatePaymentIntent)]);
}

export default paymentSaga;
