import produce from 'immer';
import { TutorType } from './types';

const initialState = {
  isActive: false,
  tutorStep: 1,
  refs: null,
  dev: false
};

export const Tutor = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case TutorType.SWITCH_TUTOR:
        draftState.isActive = !state.isActive;
        break;
      case TutorType.COMPONENT_REFS:
        draftState.refs = action.payload;
        break;
      case TutorType.SET_STEP:
        draftState.tutorStep = action.payload;
        break;
      case TutorType.START_DEV:
        draftState.dev = action.payload;
        break;
      case TutorType.STOP_DEV:
        draftState.dev = false;
        draftState.isActive = false;
        break;
      default:
        break;
    }
  });
  return nextState;
};
