export const CustomerTypes = {
  GET_PREFERENCE: 'CUSTOMER::GET_PREFERENCE',
  GET_INFO: 'CUSTOMER::GET_INFO',
  GET_INFO_SUCCESS: 'CUSTOMER::GET_INFO_SUCCESS',
  GET_INFO_FAILED_404: 'CUSTOMER::GET_INFO_FAILED_404',
  CREATE_ORGANIZATION_SUCCESS: 'CUSTOMER::CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_PREFERENCE_SUCCESS:
    'CUSTOMER::CREATE_ORGANIZATION_PREFERENCE_SUCCESS',
  GET_ORGANIZATION_INFO: 'CUSTOMER::GET_ORGANIZATION_INFO',
  GET_ORGANIZATION_INFO_SUCCESS: 'CUSTOMER::GET_ORGANIZATION_INFO_SUCCESS',
  SET_AUTH_TOKEN: 'CUSTOMER::SET_AUTH_TOKEN',
  CLEAR_CUSTOMER: 'CUSTOMER::CLEAR_CUSTOMER'
};
