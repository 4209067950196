import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ActionBar from '../../components/actionbar';
import DataTable from '../../components/datatable';
import Loader from '../../components/Loader';
import PageTitle from '../../components/PageTitle';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { getData } from '../../utils/api';
import { DateCellRenderer } from '../../utils/datatable';

const initialSort = [
  {
    id: 'createDate',
    desc: true
  }
];

const ListAllCampaigns = () => {
  const notyf = useContext(NotyfContext);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const customerState = useSelector((state) => state.Customer);
  const authState = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (customerState.info.emailVerified) {
      const fetchCampaigns = async () => {
        try {
          setLoading(true);
          const getCampaignResponse = await getData(
            ApiRoutes.getAllCampaignsRoutes(customerState.info.customerId)
          );
          setCampaigns(getCampaignResponse.campaigns);
        } catch (err) {
          notyf.open({ type: 'error', message: 'Unable to get campaigns.' });
        } finally {
          setLoading(false);
        }
      };

      fetchCampaigns();
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'campaignName'
      },
      {
        Header: 'Budget ($)',
        accessor: 'budget'
      },
      {
        Header: 'Reward/Response ($)',
        accessor: 'rewardPerResponse'
      },
      {
        Header: 'Owner Email',
        accessor: 'ownerCustomerEmail'
      },
      {
        Header: 'Reason',
        accessor: 'reason'
      },
      {
        Header: 'Create Date',
        accessor: 'createDate',
        Cell: ({ value }) => DateCellRenderer(value)
      }
    ],
    []
  );

  const createCampaignBtn = (
    <Button
      className="btn btn--primary "
      onClick={() => navigate('/campaign/create')}
      color="primary"
      id="create-campaign-button"
      disabled={!(customerState && customerState.info.emailVerified)}
      tabIndex="0"
      data-bs-toggle="tooltip"
      title={
        customerState && customerState.info.emailVerified
          ? 'Create Campaign'
          : 'Please verify email to create Campaign'
      }
    >
      Create Rewards Campaign
    </Button>
  );

  return (
    <React.Fragment>
      <Helmet title="Campaigns" />
      <Card>
        <Card.Header>
          <h1>Rewards Campaigns</h1>
        </Card.Header>
      </Card>

      <Card className="h-90 " id="campaign_card">
        <Card.Body className="h-100">
          {/* Removed these Divs since they made dark mode look ugly & IDK why they're here */}
          {/* <div className="page__wrapper">
            <div className="position-relative action-bar-container">
              <div id="campaigin-actionbar"></div>
            </div> */}
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div id="campaign-table"></div>
              <div>
                <DataTable
                  columns={columns}
                  data={campaigns}
                  sortBy={initialSort}
                />
              </div>
            </div>
          )}
          {/* </div> */}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ListAllCampaigns;
