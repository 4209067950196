import { Formik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { createOrganizationSuccess } from '../../redux/customer/actions';
import * as Api from '../../utils/api';
import DefaultOrgLogo from '../../assets/img/organizations_sm.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
const Dropzone = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */
  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag image here, or click to select files
          </p>
        )}
      </div>
    </div>
  );
};

const Organizations = () => {
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [orgLogo, setLogo] = useState();
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const ValidationSchema = Yup.object().shape({
    organizationName: Yup.string().required('Required')
  });
  const customerState = useSelector((state) => state.Customer);

  useEffect(() => {
    if (
      customerState.info.organizations &&
      customerState.info.organizations[0]
    ) {
      setOrganization(customerState.info.organizations[0]);
      if (customerState.info.organizations[0].organizationLogo) {
        setLogo({
          isLogoUpdated: false,
          logo: customerState.info.organizations[0].organizationLogo
        });
      } else {
        setLogo({
          isLogoUpdated: false
        });
      }
    }
  }, []);

  /**
   * Updates Organization Details - name and logo
   * @param {*} value
   * @param {*} action
   */
  const handleUpdate = async (value, action) => {
    let requestData = {};
    requestData['organizationName'] = value.organizationName;
    requestData['organizationId'] = value.organizationId;
    if (orgLogo && orgLogo.isLogoUpdated) {
      requestData['logo'] = orgLogo.logo;
    }
    setLoading(true);
    try {
      await Api.postData(ApiRoutes.updateOrganizationRoute(), requestData);
      dispatch(createOrganizationSuccess(requestData));
      setLoading(false);
      notyf.open({ type: 'success', message: 'Successfully updated' });
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Unable to update.' });
      setLoading(false);
    }
  };

  const uploadImage = async (event) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append('file', event.target.files[0]);
      formdata.append('resourceType', 'ORGANIZATION_LOGO');
      let response = await Api.postFile(ApiRoutes.uploadImageUrl(), formdata);
      setLogo({
        isLogoUpdated: true,
        logo: response.s3Url
      });
      setLoading(false);
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Failed to upload' });
      setLoading(false);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    console.log(acceptedFiles);
    try {
      let formdata = new FormData();
      formdata.append('file', acceptedFiles[0]);
      formdata.append('resourceType', 'ORGANIZATION_LOGO');
      let response = await Api.postFile(ApiRoutes.uploadImageUrl(), formdata);
      setLogo({
        isLogoUpdated: true,
        logo: response.s3Url
      });
      setLoading(false);
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Failed to upload' });
      setLoading(false);
    }
    closeModal();
  }, []);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '99'
    }
  };
  const dropzoneStyles = {
    content: {
      padding: '20px'
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Organization info
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <Dropzone onDrop={onDrop} accept={'image/*'} />
        </Modal>
        {loading && <Loader />}
        <Formik
          initialValues={{
            organizationName:
              customerState.info.organizations[0].organizationName,
            organizationId: customerState.info.organizations[0].organizationId
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, action) => {
            handleUpdate(values, action);
          }}
        >
          {(formikProps) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid
            } = formikProps;
            return (
              <Form className="mx-5" onSubmit={handleSubmit}>
                <Row>
                  <Col md="8">
                    <Form.Group className="mb-3">
                      <Form.Label>Organization Name</Form.Label>
                      <Form.Control
                        id="organizationName"
                        type="text"
                        name="organizationName"
                        isInvalid={
                          touched.organizationName && !!errors.organizationName
                        }
                        value={values.organizationName}
                        placeholder="Enter Organization Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.organizationName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3">
                      <div>
                        <div className="text-center">
                          <img
                            alt="default Organization"
                            src={
                              orgLogo && orgLogo.logo
                                ? orgLogo.logo
                                : DefaultOrgLogo
                            }
                            className="img-responsive mt-2"
                            width="182"
                            height="137"
                          />
                          <div className="mt-2">
                            <Button
                              variant="primary"
                              onClick={() => {
                                openModal();
                              }}
                            >
                              <FontAwesomeIcon icon={faUpload} /> Edit
                            </Button>
                            <input
                              type="file"
                              className="d-none"
                              id="organization_image"
                              onChange={uploadImage}
                            />
                          </div>
                          <small>
                            For best results, use an image at least 128px by
                            128px in .jpg and .png format
                          </small>
                        </div>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.organizationName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-2"
                  disabled={!isValid}
                >
                  Save changes
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default Organizations;
