import React, { useEffect, useContext, useState } from 'react';
import { Send, send } from 'react-feather';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Api from '../../utils/api';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';

function Fyncall() {
  const CustomerState = useSelector((state) => state.Customer);
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  /**
   * Handles the sending of 1st Text message for a FynCall
   * @param {H} bizName
   * @param {*} theirCountryCode
   * @param {*} theirNumber
   * @param {*} callTime
   * @param {*} cashPerMin
   * @param {*} lengthOfCallInMinutes
   */
  const sendMessage = async (
    bizName,
    theirCountryCode,
    theirNumber,
    callTime,
    cashPerMin,
    lengthOfCallInMinutes,
    textDelayInMinutes,
    timeZone
  ) => {
    let requestData = {};
    requestData['auth0Id'] = CustomerState.info.auth0Id;
    requestData['businessName'] = bizName;
    requestData['countryCode'] = theirCountryCode;
    requestData['lengthOfCallTime'] = lengthOfCallInMinutes;
    requestData['number'] = theirNumber;
    requestData['startTime'] = callTime;
    requestData['textDelayInMinutes'] = textDelayInMinutes;
    requestData['timeZone'] = timeZone;
    requestData['usdAmountPerMin'] = cashPerMin;

    try {
      await Api.postDataWithoutAuth(
        ApiRoutes.sendFirstTextFynCallRoute(),
        requestData
      );
      setLoading(false);
      notyf.open({
        type: 'success',
        message: 'FynTexts were successfully scheduled.'
      });
    } catch (err) {
      if (err.status === 400) {
        notyf.open({
          type: 'error',
          message: 'Invalid phone number in the request.'
        });
      } else if (err.status === 401) {
        notyf.open({
          type: 'error',
          message: 'You have an Invalid Authentication ID.'
        });
      } else if (err.status === 426) {
        notyf.open({
          type: 'error',
          message:
            'Your organization must request access to FynText / FynCall capabilitie'
        });
      } else {
        notyf.open({
          type: 'error',
          message: 'Unable to send texts, please try again.'
        });
        console.log('other error');
      }
    }
  };

  const extractTable = () => {
    let data = document.querySelector('textarea[name=excel_data]').value;
    var rows = data.split('\n');
    console.log(rows);
    var table = document.createElement('table');
    table.classList.add('table');
    let rowCount = 0;
    //unsure how to grab/set this right now.... will keep as manual
    var bizName = 'FynCom';
    for (var y in rows) {
      console.log('this another row');
      var cells = rows[y].split('\t');
      var row = table.insertRow(0);
      var numOfCellsInRow = Object.keys(cells).length;
      console.log('number of cells in row is ', numOfCellsInRow);
      var i = 0;
      for (var x in cells) {
        console.log('dis another cell');
        switch (i) {
          case 0:
            cells[i] = cells[i].replace(/-/g, '');
            var countryCode = cells[i];
            var cell1 = row.insertCell(i);
            cell1.innerHTML = countryCode;
            console.log('Country Code = ' + countryCode);
            // row.append('<td>' + countryCode + '</td>');
            break;
          case 1:
            var outboundNumber = cells[i];
            console.log('num = ' + outboundNumber);
            var cell2 = row.insertCell(i);
            cell2.innerHTML = outboundNumber;
            // row.append('<td>' + outboundNumber + '</td>');
            break;
          case 2:
            var cashPerMinute = parseFloat(cells[i]);
            console.log('CPM = ' + cashPerMinute);
            var cell3 = row.insertCell(i);
            cell3.innerHTML = cashPerMinute; //usdAmountPerMin
            // row.append('<td>' + cashPerMinute + '</td>');
            break;
          case 3:
            var timeOfCall = cells[i];
            console.log('time of call = ' + timeOfCall);
            // row.append('<td>' + timeOfCall + '</td>');
            var cell4 = row.insertCell(i);
            cell4.innerHTML = timeOfCall;
            break;
          case 4:
            var amOrPm = cells[i]; //
            console.log('Am or PM = ' + amOrPm); //
            var cell5 = row.insertCell(i);
            cell5.innerHTML = amOrPm;
            // row.append('<td>' + amOrPm + '</td>');
            break;
          case 5:
            var lengthOfCallTime = cells[i];
            console.log('Length of call time = ' + lengthOfCallTime);
            var cell6 = row.insertCell(i);
            cell6.innerHTML = lengthOfCallTime;
            // row.append('<td>' + lengthOfCallTime + '</td>');
            break;
          case 6:
            console.log('in case 6 tho....');
            var textDelayInMinutes = cells[i];
            console.log('textDelayInMinutes = ' + textDelayInMinutes);
            var cell7 = row.insertCell(i);
            cell7.innerHTML = textDelayInMinutes;
            break;
          case 7:
            var timeZone = cells[i];
            console.log('timeZone = ' + timeZone);
            var cell8 = row.insertCell(i);
            cell8.innerHTML = timeZone;
            break;
        }
        if (i == numOfCellsInRow - 1) {
          //Send info to backend.
          console.log(
            'bizname= ' +
              bizName +
              ' --outboundCountryCode= ' +
              countryCode +
              ' --ouboundNumber= ' +
              outboundNumber +
              ' --time= ' +
              timeOfCall.concat(amOrPm) +
              ' --CPM= ' +
              cashPerMinute +
              ' --lengthCallTime= ' +
              lengthOfCallTime +
              ' --textDelayInMinutes= ' +
              textDelayInMinutes +
              ' --timeZone= ' +
              timeZone
          );
          sendMessage(
            bizName,
            countryCode,
            outboundNumber,
            timeOfCall.concat(amOrPm),
            cashPerMinute,
            lengthOfCallTime,
            textDelayInMinutes,
            timeZone
          );
        }
        i += 1;
        rowCount++;
        // $('#excel_table').html(table);
      }
      // table.insertRow(row);
      console.log(table);
    }
    document.querySelector('#excel_table').appendChild(table);
  };

  return (
    <section>
      <div id="Phone Number Entry" className="network">
        <div className="container">
          <div className="col-md-12">
            <form id="phoneNumberInput">
              <h3>Enter business name below</h3>

              <input
                type="text"
                name="bizName"
                id="bizName"
                className="form-control width100"
                placeholder="Business name here. Default is FynCom"
              />

              <p>
                Format your table as shown - This can be copied/pasted into
                excel. An SMS is sent 20 minutes before the time of the call
                here.{' '}
              </p>
              <table className="table">
                <tr>
                  <th>Country Code</th>
                  <th>Phone Number to Send SMS to</th>
                  <th>$ / min Offered in SMS</th>
                  <th>Time of Call in SMS</th>
                  <th>AM / PM</th>
                  <th>Length of Call Time (mins)</th>
                  <th>text delay (mins)</th>
                  <th>time zone (PST - EST)</th>
                </tr>
                <tr>
                  <td width="10%">US</td>
                  <td width="25%">949-701-8033</td>
                  <td width="15%">15</td>
                  <td width="20%">2:01</td>
                  <td width="10%">PM</td>
                  <td width="5%">1</td>
                  <td width="5%">20</td>
                  <td width="10%">PST</td>
                </tr>
                <tr>
                  <td>US</td>
                  <td>209-631-3006</td>
                  <td>30</td>
                  <td>2:05</td>
                  <td>PM</td>
                  <td>3</td>
                  <td>20</td>
                  <td>MST</td>
                </tr>
                <tr>
                  <td>US</td>
                  <td>949-415-6441</td>
                  <td>20</td>
                  <td>2:10</td>
                  <td>PM</td>
                  <td>5</td>
                  <td>60</td>
                  <td>CST</td>
                </tr>
                <tr>
                  <td>US</td>
                  <td>310-123-6546</td>
                  <td>20</td>
                  <td>2:17</td>
                  <td>PM</td>
                  <td>2</td>
                  <td>120</td>
                  <td>EST</td>
                </tr>
              </table>

              <p>The SMS that will be sent looks like </p>
              <div>
                Get VARIABLE=$/min per minute for a VARIABLE=LengthOfCallTime
                minute call. Answer a call from VARIABLE=BUSINESS_NAME at
                VARIABLE=TIME_OF_CALL. Visit FynCom.com/why for more. Reply STOP
                to unsubscribe.
              </div>

              <p>Paste excel data below.</p>

              {/* <span className="input-group-btn">
                <button type="submit" className="btn btn-info">
                  Send SMS
                </button>
              </span> */}
              <div>
                <textarea
                  name="excel_data"
                  className="msg-container"
                ></textarea>
              </div>
              <div>
                <Send className="send-icon" />
              </div>

              <p align="a">
                Table data will appear below - Copy/save results to refer to
                later.
              </p>
            </form>
          </div>
          <div>
            <hr></hr>
            <Button onClick={() => extractTable()} variant="primary">
              Schedules Texts & Show Table
            </Button>
            <div id="excel_table">
              <hr></hr>
              <table id="extracted-table"></table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Fyncall;
