import { Alert } from 'react-bootstrap';
import { RefreshCcw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerInfo } from '../../redux/customer/actions';

export const VerifiyEmailUI = () => {
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state.Customer);
  return (
    <div>
      {!(customerState && customerState.info.emailVerified) ? (
        <div
          onClick={() => {
            dispatch(getCustomerInfo(customerState.info.auth0Id));
          }}
        >
          <div className="row">
            <div className="col-10">
              <Alert variant="warning">
                <div className="alert-message text-center">
                  ***Please verify your email***
                </div>
              </Alert>
            </div>
            <div className="col-2">
              <div className="row refresh-note-container">
                <div className="col-4">
                  <RefreshCcw className="refresh-icon-style" />
                </div>
                <div className="col-8 refresh-note">
                  If you have already verified your email, click here
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
