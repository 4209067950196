import React, { useCallback } from 'react';
import { Card, Row, Form, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarImg from '../../assets/img/avatar.png';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import * as Api from '../../utils/api';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { userPreference } from '../../redux/auth/actions';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
const Dropzone = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });
  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag image here, or click to select files
          </p>
        )}
      </div>
    </div>
  );
};

function Profile() {
  const [profile, setProfile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const notyf = React.useContext(NotyfContext);
  const customerState = useSelector((state) => state.Customer);
  const userPrefState = useSelector((state) => state.Auth.userPref);
  const userState = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (userPrefState.profilePicture) {
      setProfile({
        isLogoUpdated: false,
        img: userPrefState.profilePicture
      });
    } else {
      setProfile({
        isLogoUpdated: false,
        img: AvatarImg
      });
    }
  }, []);

  const uploadImage = async (event) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append('file', event.target.files[0]);
      formdata.append('resourceType', 'PROFILE_PICTURE');
      let response = await Api.postFile(ApiRoutes.uploadImageUrl(), formdata);
      setProfile({
        isLogoUpdated: true,
        img: response.s3Url
      });
      setLoading(false);
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Failed to upload' });
      setLoading(false);
      setProfile({
        isLogoUpdated: false,
        img: response.s3Url
      });
    }
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append('file', acceptedFiles[0]);
      formdata.append('resourceType', 'PROFILE_PICTURE');
      let response = await Api.postFile(ApiRoutes.uploadImageUrl(), formdata);
      setProfile({
        isLogoUpdated: true,
        img: response.s3Url
      });
      setLoading(false);
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Failed to upload' });
      setLoading(false);
      setProfile({
        isLogoUpdated: false,
        img: response.s3Url
      });
    }
    closeModal();
  }, []);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '99'
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const updateCustomerPreference = async () => {
    let requestData = {};
    requestData['auth0Id'] = customerState.info.auth0Id;
    if (profile.isLogoUpdated) {
      requestData['profilePicture'] = profile.img;
    }
    setLoading(true);
    try {
      var response = await Api.postData(
        ApiRoutes.updateCustomerPreference(),
        requestData
      );
      //this appears to try to update auth0Id, but it should update our own backend instead....
      //NOTE - I suspect this is not saving response .....  or requestData? Doesn't seem to matter
      dispatch(userPreference(customerState.info.auth0Id));
      // dispatch(createOrganizationSuccess(requestData));
      setLoading(false);
      notyf.open({ type: 'success', message: 'Successfully updated' });
    } catch (exception) {
      notyf.open({ type: 'error', message: 'Unable to update.' });
      setLoading(false);
    }
  };

  const Navigation = () => (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Profile Settings
        </Card.Title>
      </Card.Header>
    </Card>
  );

  const publicInfo = () => (
    <Card>
      <Card.Header>
        {/* <Card.Title tag="h5" className="mb-0">
          
        </Card.Title> */}
      </Card.Header>
      <Card.Body>
        {loading ? (
          <Loader />
        ) : (
          <Form>
            <div className="profile-page-grid-container">
              <div>
                <h5 className="m-3 h1">Public info</h5>

                <div className="p-3">
                  <span className="h4">Name: {userState.name}</span>
                  <br></br>
                  <span className="h4">Email ID: {userState.email}</span>
                </div>
              </div>
              <div>
                <div>
                  <div className="mt-2">
                    <div>
                      <img
                        alt="Profile-picture"
                        src={profile ? profile.img : AvatarImg}
                        className="rounded-circle img-responsive mt-2 profile-img"
                        width="128"
                        height="128"
                        onClick={() => {
                          openModal();
                        }}
                      />
                    </div>

                    <div className="my-4">
                      <input
                        type="file"
                        className="d-none"
                        id="profile_id"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <Button
                        variant="primary profile-upload-btn"
                        className="px-4"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <FontAwesomeIcon icon={faUpload} /> Edit
                      </Button>
                    </div>
                  </div>

                  <small>
                    For best results, use an image at least 128px by 128px in
                    .jpg and .png format
                  </small>
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              onClick={() => updateCustomerPreference()}
            >
              Save changes
            </Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <Dropzone onDrop={onDrop} accept={'image/*'} />
        </Modal>
        <Card>
          <Card.Header>
            <h1>Personal Settings</h1>
          </Card.Header>
        </Card>{' '}
        <Row>
          <Col md="3" xl="2">
            {Navigation()}
          </Col>
          <Col md="9" xl="10">
            {publicInfo()}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Profile;
