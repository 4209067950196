import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { constantKeys } from '../../constants';

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children, pathname }) => {
  const { isAuthenticated } = useAuth0();
  const token = localStorage.getItem(constantKeys.AUTH_TOKEN);
  if (!token) {
    if (!isAuthenticated) {
      return <Navigate to="/auth/sign-in" state={{ pathname }} />;
    }
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
