import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';
import { ApiRoutes } from '../../constants/api-routes';
import { getData, postData } from '../../utils/api';
import { getRandomBetweenRange } from '../../utils/common';
import { setNotification } from '../notification/actions';
import {
  createOrganizationSuccess,
  createOrganizationPreferenceSuccess,
  getCustomerInfo,
  getCustomerInfoFailed404,
  getCustomerInfoSuccess,
  getOrganizationInfoSuccess
} from './actions';
import { CustomerTypes } from './types';

function* getCustomerInfoSaga(action) {
  const { auth0Id, remainingRetrial } = action.payload;

  try {
    let customerInfoResponse = yield call(
      getData,
      ApiRoutes.getCustomerAccountInfoRoute(auth0Id)
    );
    yield put(getCustomerInfoSuccess(customerInfoResponse));

    // if customer doesn't have organization, call api to create organization
    if (customerInfoResponse.organizations.length === 0) {
      const createOrgResponse = yield call(
        postData,
        ApiRoutes.createOrganizationRoute(),
        { customerId: customerInfoResponse.customerId }
      );
      yield put(createOrganizationSuccess(createOrgResponse));
    } else {
      // note - organization preferences was added - since we are going to add that to customer info... I don't think we need to do anything here. Might suffer bloat lter.
      yield put(getCustomerInfoSuccess(customerInfoResponse));
    }
  } catch (error) {
    if (error.status === 404) {
      yield put(getCustomerInfoFailed404(auth0Id, remainingRetrial - 1));
    } else {
      yield put(
        setNotification(
          'accountSetup',
          'error',
          'Unable to retrieve customer info'
        )
      );
    }
  }
}

function* getOrganizationInfoSaga() {
  const state = yield select();
  const customerState = state.Customer;
  const organizationId = customerState.info.organizations[0].organizationId;

  try {
    const getOrganizationInfoResponse = yield call(
      getData,
      ApiRoutes.getOrganizationInfoRoute(organizationId)
    );
    yield put(getOrganizationInfoSuccess(getOrganizationInfoResponse));
  } catch (err) {
    yield put(
      setNotification(
        'organizationInfo',
        'error',
        'Unable to retrieve organization info'
      )
    );
  }
}

function* getCustomerInfoFailed404Saga(action) {
  const { auth0Id, remainingRetrial } = action.payload;
  if (remainingRetrial > 0) {
    // delay the next call for 8-15s
    const milliseconds = getRandomBetweenRange(8, 15) * 1000;
    yield delay(milliseconds);
    yield put(getCustomerInfo(auth0Id, remainingRetrial));
  } else {
    yield put(
      setNotification(
        'accountSetup',
        'error',
        'Unable to retrieve customer info'
      )
    );
  }
}

function* watchGetCustomerInfo() {
  yield takeEvery(CustomerTypes.GET_INFO, getCustomerInfoSaga);
}

function* watchGetOrganizationInfo() {
  yield takeEvery(CustomerTypes.GET_ORGANIZATION_INFO, getOrganizationInfoSaga);
}

function* watchGetCustomerInfoFailed404() {
  yield takeEvery(
    CustomerTypes.GET_INFO_FAILED_404,
    getCustomerInfoFailed404Saga
  );
}

function* customerSaga() {
  yield all([
    fork(watchGetCustomerInfo),
    fork(watchGetCustomerInfoFailed404),
    fork(watchGetOrganizationInfo)
  ]);
}

export default customerSaga;
