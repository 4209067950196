import { faSync, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Dropdown,
  Badge
} from 'react-bootstrap';
import { Info } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import EmailInput from '../../components/base/email-input';
import QuillEditor from '../../components/base/quill-editor';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { postData } from '../../utils/api';
import { chevronDown } from 'react-feather';
import { setStepCount } from '../../redux/tutor/actions';

const initialEmailValues = {
  campaign: '',
  to: [],
  subject: '',
  body: '',
  from: '',
  nickname: ''
};

const ValidationSchema = Yup.object().shape({
  campaign: Yup.string().required('Please select campaign'),
  to: Yup.array()
    .of(Yup.string().email())
    .min(1, 'Please enter receivers address'),
  subject: Yup.string().required('Email subject cannot be empty'),
  body: Yup.string().required('Email body cannot be empty')
});

const BasicEmail = (props) => {
  const { campaigns, senders, handleRefreshSenders } = props;
  const notyf = useContext(NotyfContext);
  const customerState = useSelector((state) => state.Customer);
  const [kubikDataFlag, setKubikDataFlag] = useState(false);
  const [surveyId, setsurveyId] = useState();
  const tutorState = useSelector((state) => state.Tutor);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.code === 'KeyK' && event.shiftKey && event.ctrlKey) {
        setKubikDataFlag(!kubikDataFlag);
      }
    });
  }, [kubikDataFlag]);

  const handleSendEmail = async (values, actions) => {
    if (customerState.info.emailVerified) {
      try {
        const { organizationId } = customerState.info.organizations[0];
        let request;

        if (!kubikDataFlag) {
          request = {
            from: values.from,
            to: values.to,
            subject: values.subject,
            body: values.body.replaceAll(
              '<p>',
              '<p style="margin-top : 0px !important; margin-bottom : 0px !important;"> &nbsp;'
            ),
            campaignId: values.campaign,
            customerId: customerState.info.customerId,
            organizationId
          };
        } else {
          request = {
            from: values.from,
            to: values.to,
            subject: values.subject,
            body: values.body.replaceAll(
              '<p>',
              '<p style="margin-top : 0px !important; margin-bottom : 0px !important;"> &nbsp;'
            ),
            campaignId: values.campaign,
            customerId: customerState.info.customerId,
            organizationId,
            surveyId
          };
        }

        if (!kubikDataFlag) {
          let res = await postData(ApiRoutes.sendEmailRoute(), request);
          if (tutorState.isActive && tutorState.tutorStep == 8) {
            dispatch(setStepCount(tutorState.tutorStep + 1));
          }

          if (
            res.message ===
            'This email has already received a reward for this campaign'
          ) {
            notyf.open({
              type: 'warning',
              message:
                'This email has already received a reward for this campaign'
            });
          } else {
            notyf.open({
              type: 'success',
              message: 'Successfully sent the email'
            });
          }
        } else {
          // todo set the kubik data endoint here
          await postData(ApiRoutes.getKDEmailBasic(), request);
          notyf.open({
            type: 'success',
            message: 'Successfully sent the email with lead form'
          });
        }
      } catch (err) {
        console.log('error: %o', err);
        notyf.open({ type: 'error', message: 'Unable to send the email' });
      }
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="text-dark">
        In order to use your own emails, please input your API key in the
        “Settings” page.
      </Popover.Body>
    </Popover>
  );

  const toggleDropDownMenu = () => {
    let doc = document.getElementById('sender_menu');
    if (doc.classList.contains('show')) {
      doc.classList.remove('show');
    } else {
      doc.classList.add('show');
    }
  };

  return (
    <Formik
      initialValues={initialEmailValues}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => handleSendEmail(values, actions)}
    >
      {(formikProps) => {
        const {
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Rewards Campaign</Form.Label>
              <Form.Select
                id="campaign"
                name="campaign"
                onBlur={handleBlur}
                isInvalid={touched.campaign && !!errors.campaign}
                onChange={handleChange}
                value={values.campaign}
              >
                <option value="" key="option-emtpy">
                  Select rewards campaign
                </option>
                {campaigns.map((campaign) => (
                  <option
                    value={campaign.campaignId}
                    key={`option-${campaign.campaignId}`}
                  >
                    {campaign.campaignName +
                      '\t(reward: $' +
                      campaign.rewardPerResponse +
                      ')'}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.campaign}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <div className="row">
                <div className="col-6">
                  <Form.Label>From</Form.Label>
                  {senders.length <= 1 ? (
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      overlay={popover}
                    >
                      <Info style={{ height: '17px', width: '17px' }} />
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-6">
                  <Button
                    variant="secondary"
                    className="me-1 mb-1 float-end"
                    onClick={() => handleRefreshSenders()}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>

              <div>
                <div className="dropdown sender-dropdown">
                  <div
                    className="w-100 py-2 pl-2 "
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="true"
                    data-bs-toggle="dropdown"
                    onClick={() => {
                      let doc = document.getElementById('sender_menu');
                      if (doc.classList.contains('show')) {
                        doc.classList.remove('show');
                      } else {
                        doc.classList.add('show');
                      }
                    }}
                  >
                    <span className="ps-2">
                      {values.from ? values.from : 'Sender email'}
                    </span>
                    <span className="float-end px-3">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </div>

                  <ul
                    aria-labelledby="dropdownMenuButton1"
                    className="dropdown-menu w-100 sender-list"
                    id="sender_menu"
                  >
                    {senders.map((sender) => {
                      return (
                        <li
                          className="my-2 mx-2"
                          key={`sender-option-${sender.senderId}`}
                          className={
                            values.nickname === sender.nickname
                              ? 'selected-sender'
                              : 'sender'
                          }
                          onClick={(e) => {
                            setFieldValue('from', sender.fromEmail);
                            setFieldValue('nickname', sender.nickname);
                            toggleDropDownMenu();
                          }}
                        >
                          <div className="row ml-2">
                            <div className="col-8">
                              <div>
                                <Badge>From</Badge>
                                {sender.fromEmail}
                              </div>
                              <div>
                                <Badge>Reply-to</Badge>
                                {sender.replyTo}
                              </div>
                            </div>
                            <div className="col-4">{sender.nickname}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.fromEmail}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>To</Form.Label>
              <EmailInput
                id="to-email-input"
                name="to"
                placeholder="Enter email address"
                value={values.to}
                onBlur={handleBlur}
                touched={touched.to}
                onChange={(toValue) => setFieldValue('to', toValue)}
                error={errors.to}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="subject"
                name="subject"
                placeholder="Enter subject"
                onBlur={handleBlur}
                value={values.subject}
                onChange={handleChange}
                isInvalid={touched.subject && !!errors.subject}
              />
              <Form.Control.Feedback type="invalid">
                {errors.subject}
              </Form.Control.Feedback>
            </Form.Group>
            {kubikDataFlag ? (
              <Form.Group className="mb-3">
                <Form.Label>Lead Form Id</Form.Label>
                <Form.Control
                  id="lead-form-customerId"
                  type="text"
                  name="surveyId"
                  placeholder="Enter Lead Form"
                  onChange={(e) => {
                    setsurveyId(e.target.value);
                  }}
                />
              </Form.Group>
            ) : (
              ''
            )}

            <Form.Group className="mb-3 position-relative" id="quill-editor">
              <Form.Label>Body</Form.Label>
              <QuillEditor
                name="body"
                value={values.body}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('body', e)}
                error={errors.body}
                touched={touched.body}
              />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
            <div className="horizontal-center">
              <Button
                variant="primary"
                className="form-btn"
                disabled={isSubmitting}
                type="submit"
              >
                Send email
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BasicEmail;
