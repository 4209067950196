import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="mailto: support@fyncom.com?subject=Dashboard help">
                Support
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.fyncom.com/help-center">Help Center</a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.fyncom.com/privacy-policy">Privacy</a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.fyncom.com/terms-of-service">
                Terms of Service
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.fyncom.com/terms">Terms of Use</a>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{' '}
            <span href="/" className="text-muted">
              FynCom
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
