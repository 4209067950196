import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner, Modal, Button } from 'react-bootstrap';
import { Briefcase, DollarSign } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { ApiRoutes } from '../../../constants/api-routes';
import NotyfContext from '../../../contexts/NotyfContext';
import * as Api from '../../../utils/api';
import { convertCentsToUSD } from '../../../utils/common';
import { CreditCard } from 'react-feather';
import DashboardIcon from '../../../assets/img/dashboard.png';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/pages/_statsModal.scss';

const AvgRewardWidget = ({ avgReward }) => (
  <>
    <span className="balance-str d-inline-block mt-1 mb-3">{avgReward}</span>
  </>
);

const Statistics = (props) => {
  const [avgRewardPerResponse, setAvgRewardPerResposne] = useState();
  const [transactionAvgReward, setTransactionAvgReward] = useState();

  const customerState = useSelector((state) => state.Customer);
  const [organization, setOrganization] = useState();
  const notify = useContext(NotyfContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let infoState = customerState.info;
    if (infoState.organizations && infoState.organizations[0]) {
      let organization = infoState.organizations[0];

      async function getAvgResponse() {
        try {
          let response = await Api.getData(
            ApiRoutes.avgRewardPerResponseUrl(organization.organizationId)
          );
          setAvgRewardPerResposne(response.averageRewardPerResponse);
        } catch (error) {
          if (error.status === 401) {
            navigate('/auth/signout');
          }
          notify.open({
            type: 'error',
            message: 'failed to fetch data'
          });
        }
      }
      async function getAvgTransactionalReward() {
        try {
          let transactionRewardResponse = await Api.getDataWithoutAuth(
            ApiRoutes.transactionAvgReward()
          );
          setTransactionAvgReward(
            transactionRewardResponse.averageRewardPerResponse
          );
        } catch (error) {
          if (error.status === 401) {
            navigate('/auth/signout');
          }
          notify.open({
            type: 'error',
            message: 'failed to fetch data'
          });
        }
      }

      async function getOrganizationInfo() {
        let response = await Api.getData(
          ApiRoutes.getOrganizationInfoRoute(organization.organizationId)
        );
        setOrganization(response);
      }

      getAvgTransactionalReward();
      getAvgResponse();
      getOrganizationInfo();
    }
  }, [customerState]);

  return (
    <div>
      <Row id="dashboard_org_box">
        <Col className="d-flex" sm={12} md={12} lg={4} xl={4} xs={12} id="org">
          <Card className="illustration flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0" sm={6} md={10} lg={10} xl={10} xs={6}>
                  <span className="h3 d-inline-block mt-1 mb-3 org_title">
                    Welcome Back
                  </span>
                </Col>
                <Col sm={6} md={2} lg={2} xl={2} xs={6}>
                  <div className="stat stat-sm float-end">
                    <Briefcase className="align-middle" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mt-0">
                  <span className="h2 d-inline-block mt-1 mb-3 org_title">
                    {organization && organization.organizationName
                      ? organization.organizationName
                      : ''}
                  </span>
                </Col>
                <Col className="mt-0">
                  {organization && organization.organizationLogo ? (
                    <img
                      src={organization.organizationLogo}
                      alt="Organization"
                      className="img-fluid illustration-img dashboard_logo_css"
                    />
                  ) : (
                    <img
                      src={DashboardIcon}
                      alt="Organization"
                      className="img-fluid illustration-img dashboard_logo_css"
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col
          className="d-flex"
          sm={12}
          md={12}
          lg={4}
          xl={4}
          xs={12}
          id="wallet"
        >
          <Card className="flex-fill" id="tutor_wallet">
            <Card.Body>
              <div>
                <Row>
                  <Col className="mt-0" sm={6} md={10} lg={10} xl={10} xs={6}>
                    <span className="h3 d-inline-block mt-1 mb-3 org_title">
                      Your Wallet
                    </span>
                  </Col>
                  <Col sm={6} md={2} lg={2} xl={2} xs={6}>
                    <div className="stat stat-sm float-end">
                      <CreditCard className="align-middle" />
                    </div>
                  </Col>
                </Row>
                <div className="container p-2">
                  <div className="row">
                    <div className="col-9">
                      <span className="h5 d-inline-block mt-1 mb-3">
                        Account Funds ($USD)
                      </span>
                    </div>
                    <div className="col-3">
                      {organization &&
                      organization.availableFiatBalanceInCents != undefined ? (
                        <span className="h5 d-inline-block mt-1 mb-3">
                          $
                          {convertCentsToUSD(
                            organization.availableFiatBalanceInCents
                          )}
                        </span>
                      ) : (
                        <Spinner variant="primary" animation="border" />
                      )}
                    </div>
                  </div>
                  <div className="row" id="available_Promotional_bal">
                    <div className="col-9">
                      <span className="h5 d-inline-block mt-1 mb-3">
                        Promo Funds ($USD)
                      </span>
                    </div>
                    <div className="col-3">
                      {organization &&
                      organization.availablePromotionalBalanceInCents !=
                        undefined ? (
                        <span className="h5 d-inline-block mt-1 mb-3">
                          $
                          {convertCentsToUSD(
                            organization.availablePromotionalBalanceInCents
                          )}
                        </span>
                      ) : (
                        <Spinner variant="primary" animation="border" />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <span className="h5 d-inline-block mt-1 mb-3">
                        Rewards Funds ($USD)
                      </span>
                    </div>
                    <div className="col-3">
                      {organization &&
                      organization.availableCryptoBalanceInCents !=
                        undefined ? (
                        <span className="h5 d-inline-block mt-1 mb-3">
                          $
                          {convertCentsToUSD(
                            organization.availableCryptoBalanceInCents
                          )}
                        </span>
                      ) : (
                        <Spinner variant="primary" animation="border" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-flex" sm={12} md={12} lg={4} xl={4} xs={12} id="arpr">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0" sm={6} md={10} lg={10} xl={10} xs={6}>
                  <span className="h3 d-inline-block mt-1 mb-3 org_title">
                    Average Reward per Response
                  </span>
                </Col>
                <Col sm={6} md={2} lg={2} xl={2} xs={6}>
                  <div className="stat stat-sm float-end">
                    <DollarSign className="align-middle" />
                  </div>
                </Col>
              </Row>
              <div className="mb-0 ps-2">
                {avgRewardPerResponse !== undefined ? (
                  <AvgRewardWidget avgReward={avgRewardPerResponse} />
                ) : (
                  <Spinner variant="primary" animation="border" />
                )}
              </div>
              <div>
                <div className="d-inline-block avg_reward_str">
                  Average RpR for all of FynCom (
                  <span className="ps-2">
                    {avgRewardPerResponse !== undefined ? (
                      <span>{transactionAvgReward}</span>
                    ) : (
                      <Spinner variant="primary" animation="border" />
                    )}
                  </span>
                  )
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Statistics;
