import { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Modal,
  Button,
  Form,
  Accordion,
  Card
} from 'react-bootstrap';
import Loader from '../../../components/Loader';
import { createOrganizationSuccess } from '../../../redux/customer/actions';
import * as Api from '../../../utils/api';
import { ApiRoutes } from '../../../constants/api-routes';
import OrganizationLogo from '../../../assets/img/organizations.png';
import NotyfContext from '../../../contexts/NotyfContext';
import { setStepCount, stopTutor } from '../../../redux/tutor/actions';

const StarterModal = (props) => {
  const customerState = useSelector((state) => state.Customer);
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState();
  const tutorState = useSelector((state) => state.Tutor);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const ValidationSchema = Yup.object().shape({
    organizationName: Yup.string().required('Organization name cannot be empty')
  });

  const handleUpdate = async (value) => {
    console.log('i am here to check organization');
    console.log(value.organizationName);
    let requestData = {};
    requestData['organizationName'] = value.organizationName;
    requestData['organizationId'] = value.organizationId;
    setLoading(true);
    try {
      await Api.postData(ApiRoutes.updateOrganizationRoute(), requestData);
      dispatch(createOrganizationSuccess(requestData));
      setLoading(false);
      notyf.open({ type: 'success', message: 'Successfully updated' });
      props.updateOrgStatus(true);
      props.toggle(false);
    } catch (exception) {
      console.log(exception);
      notyf.open({ type: 'error', message: 'Unable to update.' });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      customerState.info.organizations &&
      customerState.info.organizations[0]
    ) {
      setOrganizationName(customerState.info.organizations[0]);
    }
  });

  /**
   * Allows users to use the 'Enter' key to submit the change of org name
   */
  const enterKeyListener = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      handleUpdate(values);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        // show={tutorState.isActive && tutorState.tutorStep === 1}
        show={props.show}
        onHide={() => props.toggle()}
        size="lg"
      >
        <Modal.Body>
          <h1>Update your Profile</h1>
          <div className="row">
            <div className="col-6 p-5 start_form_outer_logo">
              <img src={OrganizationLogo} className="w-100" />
            </div>
            <div className="col-6 start_form_outer">
              <div className="p-5">
                <Formik
                  initialValues={{
                    organizationName:
                      customerState.info.organizations[0].organizationName,
                    organizationId:
                      customerState.info.organizations[0].organizationId
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={(values, action) => {
                    handleUpdate(values, action);
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      action,
                      handleBlur,
                      handleChange,
                      isValid
                    } = formikProps;
                    return (
                      <Form
                        className="starter_form"
                        onSubmit={enterKeyListener}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="starter_form_label">
                            What is your organization name?
                          </Form.Label>
                          <Form.Control
                            id="organizationName"
                            type="text"
                            name="organizationName"
                            className="starter_form_input"
                            isInvalid={
                              touched.organizationName &&
                              !!errors.organizationName
                            }
                            value={values.organizationName}
                            placeholder="Enter Organization Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.organizationName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div>
                          <div className="d-inline" id="create_btn">
                            <Button
                              type="submit"
                              variant="primary"
                              className="form-btn"
                              disabled={!isValid}
                              onClick={(e) => {
                                console.log(e);
                                e.preventDefault();
                                handleUpdate(values);
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div>
            <div>
              {tutorState.isActive ? (
                <Button
                  className="mx-2 float-end"
                  onClick={() => {
                    dispatch(setStepCount(3));
                  }}
                >
                  Next
                </Button>
              ) : (
                ''
              )}
              <Button
                className="float-end"
                onClick={() => {
                  if (tutorState.isActive) {
                    dispatch(stopTutor());
                  } else {
                    props.toggle();
                  }
                }}
              >
                Skip
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StarterModal;
