import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';

import { ApiRoutes } from '../../constants/api-routes';
import { getData, postData, postDataWithoutAuth } from '../../utils/api';
import { useSelector } from 'react-redux';
import { Eye, EyeOff } from 'react-feather';
import ZapierIcon from '../../assets/img/zapier.png';
import * as Api from '../../utils/api';
import { Link } from 'react-router-dom';

export default function Integration() {
  const customerState = useSelector((state) => state.Customer);
  const [showKey, toggleView] = useState(false);
  const [apikey, setApikey] = useState();

  useEffect(() => {
    getApi();
  }, []);

  const getApi = async () => {
    try {
      let response = await Api.getDataWithoutAuth(
        ApiRoutes.getApiCredentials('ZAPIER')
      );
      setApikey(response.apiKey);
    } catch (error) {
      console.log(error);
    }
  };

  const createApiKey = async () => {
    try {
      let response = await postDataWithoutAuth(ApiRoutes.setApiCredentials(), {
        service: 'ZAPIER'
      });
      getApi();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <Link to={'/settings'}> */}
      <Card>
        <Card.Header>
          <Card.Title
            tag="h5"
            className="mb-0"
            onClick={() => {
              console.log('on');
              toggleView(!showKey);
            }}
          >
            Integrations
          </Card.Title>
        </Card.Header>
        <Link to={'/settings'}>
          <Card.Body>
            <Card style={{ width: '12rem' }} className="integration-card">
              <Card.Img variant="top" src={ZapierIcon} />
            </Card>
          </Card.Body>
        </Link>
        {/* //TODO put fyncom API Key and custom logo here */}
        {/* <Link to={'/settings/fyncom'}>
          <Card.Body>
            <Card style={{ width: '12rem' }} className="integration-card">
              <Card.Img variant="top" src={FynCOm} /> 
            </Card>
          </Card.Body>
        </Link> */}
      </Card>
      {/* </Link> */}

      <Card>
        {apikey && (
          <div>
            <div className="api-key-container">
              <div>
                <h1>Zapier API Key</h1>
              </div>
              <div className="api-key-inner-container">
                <div className="row">
                  <div className="col-10">
                    <input
                      className="api-key-inputbox"
                      type={showKey ? 'text' : 'password'}
                      value={apikey}
                      disabled
                    />
                  </div>
                  <div className="col-2">
                    <div
                      className="mr-2"
                      onClick={() => {
                        toggleView(!showKey);
                      }}
                    >
                      {showKey ? <EyeOff /> : <Eye />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!apikey && (
          <div className="p-4">
            <div>
              <h1>Zapier Setup</h1>
            </div>
            <div className="zapier-setup">
              <button
                className="zapier-setup-btn"
                onClick={() => {
                  createApiKey();
                }}
              >
                Generate Zapier API Key
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
