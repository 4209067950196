import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/auth/actions';
import { clearCustomer } from '../../redux/customer/actions';
import { constantKeys } from '../../constants';
import { stopTutor, stopDev } from '../../redux/tutor/actions';

const Signout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  useEffect(() => {
    dispatch(stopTutor());
    dispatch(stopDev());
    dispatch(logoutUser());
    dispatch(clearCustomer());
    localStorage.removeItem(constantKeys.AUTH_TOKEN);
    logout();
  }, []);

  return <></>;
};

export default Signout;
