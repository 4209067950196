import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendgridLogo from '../../assets/img/sendgrid.svg';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import * as Api from '../../utils/api';

const EmailProvider = () => {
  const [apiKey, setApiKey] = useState('');
  const [sendgridObj, setSendgrid] = useState();
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);
  const customerState = useSelector((state) => state.Customer);

  useEffect(() => {
    getEmailApiKey();
  }, []);

  //to be updated with new route. Be mindful of responses
  const getEmailApiKey = async () => {
    try {
      let response = await Api.getDataWithoutAuth(
        ApiRoutes.getApiCredentials('SENDGRID')
      );
      setSendgrid(response);
      if (response.apiKey) {
        setApiKey(response.apiKey);
      }
    } catch (err) {
      console.log('No API Key Found!');
    }
  };

  const addEmailApiKey = async () => {
    let requestData = {
      apiKey,
      service: 'SENDGRID'
    };

    setLoading(true);
    try {
      await Api.postDataWithoutAuth(
        ApiRoutes.addMailProviderKeyRoute(),
        requestData
      );
      setLoading(false);
      notyf.open({
        type: 'success',
        message: 'API key successfully added. Check your email to see.'
      });
    } catch (error) {
      setLoading(false);
      const status = Object.getOwnPropertyDescriptor(error, 'status');
      if (error.status === 403) {
        notyf.open({
          type: 'error',
          message: 'That was not a valid Sendgrid API key. Please try again.'
        });
      } else if (error.status === 401) {
        notyf.open({
          type: 'error',
          message: "Your account's Role is not authorized to set API keys."
        });
      } else {
        //Note, this may have some edge cases that are note handled by backend.
        notyf.open({
          type: 'error',
          message: 'Please update the permissions of this Sendgrid API Key.'
        });
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Card>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              Email provider
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md="8">
                  <div>
                    <Form.Group className="mb-3" controlid="formBasicEmail">
                      <Form.Label>Sendgrid API key</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sendgrid API key"
                        controlid="API-keys"
                        value={apiKey}
                        onChange={(e) => {
                          setApiKey(e.target.value);
                        }}
                        required
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md="4">
                  <div className="text-center">
                    <img
                      alt="Sendgrid logo"
                      src={SendgridLogo}
                      className="rounded-circle img-responsive mt-2"
                      width="128"
                      height="128"
                    />
                    <div className="mt-2">
                      <span>Sendgrid</span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Button
                variant="primary"
                onClick={() => addEmailApiKey()}
                disabled={!apiKey}
              >
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default EmailProvider;
