import axios from 'axios';
import { store } from '../redux/store';
import { constantKeys } from '../constants';

const getDashboardUrl = () => {
  return process.env.REACT_APP_SERVER_URL;
};
const getBaseV2Url = () => {
  return process.env.REACT_APP_SERVER_BASE_V2_URL;
};

const getData = async (url, params) => {
  const state = store.getState();
  const token = state.Customer.token;
  const fyncomApiKey = state.Customer.info.fyncomApiKey;
  const options = {
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-API-KEY': fyncomApiKey
    }
  };
  const apiUrl = getDashboardUrl() + url;
  return axios
    .get(apiUrl, options)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

/**
 * If trying to submit requests to API endpoints with just a FynCom API Key and no Auth0Id
 * This is likely where you want to be.
 */
const getDataWithoutAuth = async (url, params) => {
  const state = store.getState();
  const fyncomApiKey = state.Customer.info.fyncomApiKey;
  const options = {
    params,
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': fyncomApiKey
    }
  };
  const apiUrl = getBaseV2Url() + url;
  return axios
    .get(apiUrl, options)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

/**
 * This is meant for POST'ing data using FynCom API Key. Typically an Auth0 Bearer-token is required here, but not always
 *
 *@param {*} url
 *@param {*} data
 *@returns
 */
const postDataWithoutAuth = async (url, data) => {
  const state = store.getState();
  const token = state.Customer.token;
  const fyncomApiKey = state.Customer.info.fyncomApiKey;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-API-KEY': fyncomApiKey
    }
  };
  console.log('this data is ' + JSON.stringify(data));
  const apiUrl = getBaseV2Url() + url;
  return axios
    .post(apiUrl, JSON.stringify(data), options)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

const postData = async (url, data) => {
  const state = store.getState();
  const token = state.Customer.token;
  const fyncomApiKey = state.Customer.info.fyncomApiKey;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-API-KEY': fyncomApiKey
    }
  };
  const apiUrl = getDashboardUrl() + url;
  return axios
    .post(apiUrl, JSON.stringify(data), options)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

/**
 * Using fetch here - different than than the usual style
 * @param {*} url
 */
const postUrlEncodedData = async (url) => {
  const state = store.getState();
  const token = state.Customer.token;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + token
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Status: ${response.status}, Details: ${errorDetails}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const postFile = async (url, data) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const apiUrl = getBaseV2Url() + url;
  return axios
    .post(apiUrl, data, options)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export {
  getData,
  postData,
  postFile,
  getDataWithoutAuth,
  postDataWithoutAuth,
  postUrlEncodedData
};
