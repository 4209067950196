import { AuthTypes } from './types';

export const loginUserSuccess = (user) => {
  return {
    type: AuthTypes.LOGIN_SUCCESS,
    payload: user
  };
};

export const userPreference = (pref) => {
  return {
    type: AuthTypes.PREF,
    payload: pref
  };
};

export const setUserPreference = (state) => {
  return {
    type: AuthTypes.SET_USER_PREF,
    payload: state
  };
};

export const logoutUser = () => {
  return {
    type: AuthTypes.LOGOUT
  };
};
