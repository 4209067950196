import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { ApiRoutes } from '../../../constants/api-routes';
import NotyfContext from '../../../contexts/NotyfContext';
import * as Api from '../../../utils/api';

function CreateInvitation(props) {
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const customerState = useSelector((state) => state.Customer);
  const navigate = useNavigate();

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, 'Name should have atleast one charcter')
      .required('Required'),
    email: Yup.string().email('Invalid Email').required('Required'),
    role: Yup.string().min(2, 'Invalid value').required('Required')
  });

  const handleInviteRequest = async (values, actions) => {
    actions.setSubmitting(true);
    const { name, email, role } = values;

    const requestData = {
      customerId: customerState.info.customerId,
      name,
      email,
      role
    };

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      await Api.postData(ApiRoutes.getCreateInvitationUrl(), requestData);
      setLoading(false);
      setSuccess('Invitation has been Created');
      notyf.open({
        type: 'success',
        message: 'Invitation email successfully sent to employee'
      });
      navigate('/invite');
      actions.resetForm();
    } catch (err) {
      const errorMessage = 'Unable to proceed with request';
      notyf.open({ type: 'error', message: 'Failed to invite this employee' });
      setLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <div>
      <div>
        <PageTitle
          title="Invite Employee"
          breadCrumbItems={[
            { label: 'Invites', path: '/invite' },
            { label: 'Invite Employee', path: '/invite/create', active: true }
          ]}
        />
      </div>
      <div>
        <Container fluid className="p-0">
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body>
                  {loading && <Loader />}
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      role: ''
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, action) => {
                      handleInviteRequest(values, action);
                    }}
                  >
                    {(formikProps) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting
                      } = formikProps;
                      return (
                        <Form className="mx-5" onSubmit={handleSubmit}>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              id="name"
                              type="text"
                              name="name"
                              isInvalid={touched.name && !!errors.name}
                              value={values.name}
                              placeholder="Enter Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              id="email"
                              type="email"
                              name="email"
                              isInvalid={touched.name && !!errors.email}
                              value={values.email}
                              placeholder="Enter Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select
                              defaultValue="Choose..."
                              id="role"
                              type="select"
                              name="role"
                              isInvalid={touched.name && !!errors.role}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Choose...</option>
                              <option value="ADMIN">Admin</option>
                              <option value="READ">Read</option>
                              <option value="WRITE">Write</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.role}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-4"
                            disabled={isSubmitting}
                          >
                            Invite
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CreateInvitation;
