const getUserGuid = (userSub) => {
  const idParts = userSub.split('|');
  return idParts[1];
};

const isUserAuthenticated = (authState) => {
  return authState.isAuthenticated || false;
};

export { getUserGuid, isUserAuthenticated };
