import classNames from 'classnames';
import React from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';

const EmailInput = (props) => {
  const { className, id, placeholder, onChange, value, error, touched } = props;
  const isInvalid = touched !== undefined && error !== undefined;
  return (
    <div className="multi-mail-wrapper">
      <ReactMultiEmail
        className={classNames({ className, invalid: isInvalid })}
        id={id}
        placeholder={placeholder}
        emails={value}
        onChange={onChange}
        validateEmail={(email) => {
          return isEmail(email);
        }}
        value={value}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      {isInvalid ? <div className="error-feedback">{error}</div> : ''}
    </div>
  );
};

export default EmailInput;
