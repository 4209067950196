import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

const Header = () => {
  return (
    <Card className="py-1 pl-2">
      <Card.Body>
        <Row>
          <div className="col-6">
            <h1>Dashboard</h1>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Header;
