import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';
import { ApiRoutes } from '../../constants/api-routes';
import { getData, postData } from '../../utils/api';
import { userPreference, setUserPreference } from './actions';
import { AuthTypes } from './types';

function* getUserInfoSaga(prefObj) {
  try {
    let response = yield call(
      getData,
      ApiRoutes.userPreference(prefObj.payload)
    );
    if (response.customerPreference) {
      yield put(setUserPreference(response.customerPreference));
    }
  } catch (error) {}
}

function* watchGetUserPreferenceInfo() {
  yield takeEvery(AuthTypes.PREF, getUserInfoSaga);
}

function* UserPrefSage() {
  yield all([fork(watchGetUserPreferenceInfo)]);
}

export default UserPrefSage;
