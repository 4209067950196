import React from 'react';
// Guards
import AuthGuard from './components/guards/AuthGuard';
// import Settings from './components/Settings';
// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import SignIn from './pages/auth/SignIn';
import SignInSuccess from './pages/auth/SignInSuccess';
import Signout from './pages/auth/Signout';
// Camapign
import ListAllCampaigns from './pages/campaign/';
import Integrate from './pages/campaign/integrate';
import Create from './pages/campaign/Create';
import CampaignsTransaction from './pages/campaign/transactions';
// Dashboards
import Default from './pages/dashboards/Default';
import Email from './pages/email';
//HelpCenter
import Help from './pages/help/help';
import CreateInvitation from './pages/invite/create/index';
import Invitation from './pages/invite/create/invitations';
//Invite
import Invite from './pages/invite/index';
import Landing from './pages/landing/Landing';
//Settings
import Settings from './pages/settings/index';
import Wallet from './pages/wallet';
import Profile from './pages/settings/profile';
import PaymentDetails from './pages/wallet/paymentDetails/index';

//privacy && Terms
import Privacy from './pages/privacy';
import Terms from './pages/terms';

const routes = [
  {
    path: '/',
    element: <Landing />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard pathname="/dashboard">
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Default />
      }
    ]
  },
  {
    path: 'help',
    element: (
      <AuthGuard pathname="/help">
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Help />
      }
    ]
  },
  {
    path: 'privacy',
    element: (
      <AuthGuard pathname="/privacy">
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Privacy />
      }
    ]
  },
  {
    path: 'terms',
    element: (
      <AuthGuard pathname="/terms">
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Terms />
      }
    ]
  },
  {
    path: 'settings',
    element: (
      <AuthGuard pathname="/settings">
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings',
        element: (
          <AuthGuard>
            <Settings />
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: 'campaign',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'history',
        element: (
          <AuthGuard>
            <ListAllCampaigns />
          </AuthGuard>
        )
      },
      {
        path: 'integrate',
        element: (
          <AuthGuard>
            <Integrate />
          </AuthGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AuthGuard>
            <Create />
          </AuthGuard>
        )
      },
      {
        path: 'transaction',
        element: (
          <AuthGuard>
            <CampaignsTransaction />
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: 'invite',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/invite/',
        element: (
          <AuthGuard>
            <Invite />
          </AuthGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AuthGuard>
            <CreateInvitation />
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: 'invite/employee',
    element: <Invitation />
  },
  {
    path: 'email',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/email/',
        element: <Email />
      }
    ]
  },
  {
    path: 'wallet',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/wallet/',
        element: (
          <AuthGuard>
            <Wallet />
          </AuthGuard>
        )
      },
      {
        path: '/wallet/payment-details',
        element: (
          <AuthGuard>
            <PaymentDetails />
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Profile />
      }
    ]
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in/success',
        element: <SignInSuccess />
      },
      {
        path: 'sign-in',
        element: <SignIn />
      },
      {
        path: 'signout',
        element: <Signout />
      },
      {
        path: '404',
        element: <Page404 />
      },
      {
        path: '500',
        element: <Page500 />
      }
    ]
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />
      }
    ]
  }
];

export default routes;
