import produce from 'immer';
import { PaymentTypes } from './types';

const initialState = {
  loading: false,
  intentId: null,
  clientSecret: null,
  paymentAmount: 0
};

export const Payment = (state = initialState, action) => {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case PaymentTypes.UPDATE_PAYMENT_AMOUNT:
        draftState.paymentAmount = action.payload.paymentAmount;
        break;
      case PaymentTypes.CREATE_PAYMENT_INTENT:
      case PaymentTypes.UPDATE_PAYMENT_INTENT:
        draftState.loading = true;
        draftState.clientSecret = null;
        break;
      case PaymentTypes.CREATE_PAYMENT_INTENT_SUCCESS:
      case PaymentTypes.UPDATE_PAYMENT_INTENT_SUCCESS:
        draftState.loading = false;
        draftState.clientSecret = action.payload.clientSecret;
        draftState.intentId = action.payload.intentId;
        break;
      case PaymentTypes.CLEAR_PAYMENT_INTENT:
        draftState.intentId = null;
        draftState.clientSecret = null;
        break;
      default:
        break;
    }
  });
  return nextState;
};
