import React, { useContext, useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { getData } from '../../utils/api';
import BasicEmail from './basic-email';
import MarketingEmail from './marketing-email';

const EmailUpdateAlert = (props) => {
  return (
    <Alert variant="warning" dismissible onClose={() => props.toggle()}>
      <div className="alert-message">
        Your organization name is <strong>Default Org</strong>. Before sending
        email, please update your Organization name in
        <Link className="px-2" to="/settings">
          Settings
        </Link>
      </div>
    </Alert>
  );
};

const DraftMessageAlert = (props) => {
  return (
    <Alert variant="warning">
      <div className="alert-message">{props.message}</div>
    </Alert>
  );
};

const Email = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const notyf = useContext(NotyfContext);
  const customerState = useSelector((state) => state.Customer);

  const [senders, setSenders] = useState([]);
  const [isMailUpdated, setMailUpdateFlag] = useState(false);
  const [marketingTemplatesFetched, setMarketingTemplatesFetched] =
    useState(false);
  const [marketingTemplates, setMarketingTempaltes] = useState([]);
  const [emailType, setEmailType] = useState('basic');
  const [showDraftMessageAlert, setShowDraftMessageAlert] = useState(false);
  const [draftAlert, setDraftAlert] = useState();

  useEffect(() => {
    if (customerState.info.emailVerified) {
      const { organizationName } = customerState.info.organizations[0];
      const fetchCampaigns = async () => {
        try {
          setLoading(true);
          const getCampaignResponse = await getData(
            ApiRoutes.getAllCampaignsRoutes(customerState.info.customerId)
          );

          if (getCampaignResponse.campaigns.length > 0) {
            setCampaigns(getCampaignResponse.campaigns);
          }
        } catch (err) {
          notyf.open({ type: 'error', message: 'Unable to get campaigns.' });
        } finally {
          setLoading(false);
        }
        if (organizationName === 'Default org') setMailUpdateFlag(true);
      };

      const fetchSenders = async () => {
        if (customerState.info.emailVerified) {
          const { organizationId } = customerState.info.organizations[0];
          try {
            setLoading(true);
            const response = await getData(
              ApiRoutes.getSendersURL(organizationId)
            );
            setSenders(response);
          } catch (err) {
            notyf.open({ type: 'error', message: 'Unable to get Senders.' });
          } finally {
            setLoading(false);
          }
        }
      };
      fetchCampaigns();
      fetchSenders();
    }
  }, []);

  const toggleWarning = () => {
    setMailUpdateFlag(false);
  };

  const showDraftAlert = (message) => {
    setShowDraftMessageAlert(true);
    setDraftAlert(message);
  };

  const hideDraftAlert = () => {
    setShowDraftMessageAlert(false);
  };

  const handleRefreshSenders = async () => {
    try {
      const { organizationId } = customerState.info.organizations[0];
      setLoading(true);
      const response = await getData(
        ApiRoutes.refreshSendersURL(organizationId)
      );
      setSenders(response);
    } catch (error) {
      notyf.open({ type: 'error', message: 'Unable to refresh senders.' });
    } finally {
      setLoading(false);
    }
  };

  const fetchDraftTemplates = async () => {
    if (customerState.info.emailVerified && !marketingTemplatesFetched) {
      try {
        const { organizationId } = customerState.info.organizations[0];
        setLoading(true);
        const response = await getData(
          ApiRoutes.getMarketingDraftTemplates(organizationId)
        );
        setMarketingTempaltes(response.sendgridDraftList);
        setMarketingTemplatesFetched(true);
      } catch (error) {
        console.log('error: %o', error);
        notyf.open({
          type: 'warning',
          background: 'orange',
          duration: 20000,
          message:
            'To use this feature, please update your settings with your sendgrid credentials.'
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEmailTypeChange = async (e) => {
    setEmailType(e.target.value);
    if (e.target.value === 'marketing') {
      await fetchDraftTemplates();
    }
    if (e.target.value === 'basic') {
      notyf.dismissAll();
    }
  };

  const isBasicEmailType = () => {
    return emailType === 'basic';
  };

  const isMarketingEmailType = () => {
    return emailType === 'marketing';
  };

  return (
    <React.Fragment>
      <Helmet title="Email" />
      <Container>
        <Row>
          <Col md="9">
            <Card>
              <Card.Header>
                <h1>Email</h1>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        {loading && <Loader />}
        {isMailUpdated && <EmailUpdateAlert toggle={toggleWarning.bind()} />}
        {showDraftMessageAlert && <DraftMessageAlert message={draftAlert} />}
        <Row className="scroll_email_container">
          <Col md="10" className="position-relative">
            <Card id="email-card-container">
              <Card.Body>
                <Form.Group className="email-type__input">
                  <Form.Check
                    inline
                    label="Basic Email"
                    type="radio"
                    name="radios-email-type"
                    value="basic"
                    checked={emailType === 'basic'}
                    onChange={handleEmailTypeChange}
                  />
                  <Form.Check
                    inline
                    label="Marketing Email"
                    type="radio"
                    name="radios-email-type"
                    value="marketing"
                    checked={emailType === 'marketing'}
                    onChange={handleEmailTypeChange}
                  />
                </Form.Group>
                <div>
                  <div className="position-relative">
                    <div id="tutor-email-container"></div>
                    <div>
                      {isBasicEmailType() && (
                        <BasicEmail
                          campaigns={campaigns}
                          senders={senders}
                          handleRefreshSenders={handleRefreshSenders}
                        />
                      )}

                      {isMarketingEmailType() && (
                        <MarketingEmail
                          campaigns={campaigns}
                          marketingTemplates={marketingTemplates}
                          setLoading={setLoading}
                          showDraftAlert={showDraftAlert}
                          hideDraftAlert={hideDraftAlert}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Email;
