import { combineReducers } from 'redux';
import { Auth } from './auth/reducers';
import { Customer } from './customer/reducers';
import { Notification } from './notification/reducers';
import { Payment } from './payment/reducers';
import { Tutor } from './tutor/reducers';

export default combineReducers({
  Auth,
  Customer,
  Payment,
  Notification,
  Tutor
});
