import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // This shows up 2nd on Auth0 landing widget
    console.log('in landing');
    if (!isAuthenticated && !isLoading) {
      navigate('/auth/sign-in');
    } else if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <>Loading...</>;
};

export default Landing;
