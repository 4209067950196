import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DataTable from '../../components/datatable';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import * as Api from '../../utils/api';
import { VerifiyEmailUI } from '../../components/tutorModule/commonuiutils';

function Invite() {
  const [invitedEmployeeList, setEmployeeList] = useState([]);
  const customerState = useSelector((state) => state.Customer);
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  useEffect(() => {
    getInvitedEmployeeList();
  }, []);

  const getInvitedEmployeeList = async () => {
    try {
      let queryResponse = await Api.getData(
        ApiRoutes.invitesUrls(customerState.info.customerId)
      );
      setEmployeeList(queryResponse.employeeInvitesList);
    } catch (error) {
      notyf.open({
        type: 'error',
        message: 'failed to fetch the data due to exception'
      });
    }
  };

  const initialSort = [
    {
      id: 'name',
      desc: true
    }
  ];
  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'employeeName'
    },
    {
      Header: 'Email',
      accessor: 'employeeEmail'
    },
    {
      Header: 'Role',
      accessor: 'role'
    },
    {
      Header: 'Status',
      accessor: 'inviteStatus'
    }
  ]);

  return (
    <div>
      <div>
        <div>
          <VerifiyEmailUI />
          <Card className="py-1 pl-2">
            <Card.Body>
              <Row>
                <div className="col-6">
                  <h1>Invitations</h1>
                </div>
                <div className="col-6">
                  <Button
                    className="btn btn--primary float-end"
                    onClick={() => navigate('/invite/create')}
                    color="primary"
                    id="create-invite__button"
                    disabled={
                      !(customerState && customerState.info.emailVerified)
                    }
                  >
                    Send Invitation
                  </Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div>
        {invitedEmployeeList && invitedEmployeeList.length > 0 ? (
          <DataTable
            columns={columns}
            data={invitedEmployeeList}
            sortBy={initialSort}
          />
        ) : (
          <Alert variant="primary">
            <div className="alert-message">No data available to show</div>
          </Alert>
        )}
      </div>
    </div>
  );
}

export default Invite;
