import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { devLog } from '../utils/common';

const Auth0ProviderWithNavigate = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  // this shows up 1st on Auth0 landing widget - and it shows up again (3rd total) when redirected
  console.log('auth0 navigate with ');
  devLog('This message will only be logged in the development environment.');

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    console.log('onRedirect');
    navigate('/auth/sign-in/success', {
      state: { pathname: appState?.pathname }
    });
  };

  return (
    <Auth0Provider
      // advancedOptions={{ defaultScope: '' }}
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      // scope={scope}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
