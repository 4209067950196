import React, { useState } from 'react';
import { Card, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import * as Icon from 'react-feather';
import './help.css';

function Help() {
  window.location = 'https://www.fyncom.com/help-center';

  //https://stackoverflow.com/questions/7554108/javascript-window-location-in-new-tab
  //Would prefer this code below, but it seems to break the back button. See answer from Fanis Mahmalat in SO post above

  // window.open('https://www.fyncom.com/help-center', '_blank') ||
  // window.location.replace('https://www.fyncom.com/help-center');
}

export default Help;
