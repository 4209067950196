import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendgridLogo from '../../assets/img/sendgrid.svg';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import * as Api from '../../utils/api';

const Subscription = () => {
  const [apiKey, setApiKey] = useState('');
  const [sendgridObj, setSendgrid] = useState();
  const notyf = useContext(NotyfContext);
  const [loading, setLoading] = useState(false);
  const customerState = useSelector((state) => state.Customer);
  const [chargebeeScriptLoaded, setChargebeeScriptLoaded] = useState(false);

  // This is old code from a cool payments fiat/crypto/nano widget I had called "CoinEmbed" - May consider bringing it back later
  // const [loadedCoinScript, setLoadedCoinScript] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    // const coinScript = document.createElement('script');
    script.onload = () => {
      window.Chargebee.init({ site: 'fyncom' });
      window.Chargebee.registerAgain();
      setChargebeeScriptLoaded(true); // Set chargebeeScriptLoaded to true when the script is loaded
    };
    // coinScript.onload = () => {
    //   window.CoinEmbed.loadDocumentInstances();
    //   setLoadedCoinScript(true);
    // };
    script.onerror = () => {
      setChargebeeScriptLoaded(false); // Set chargebeeScriptLoaded to false if the script fails to load
    };
    script.src = 'https://js.chargebee.com/v2/chargebee.js';
    script.async = true;
    // coinScript.src = 'https://coinembed.com/v1/coinembed.js';
    // coinScript.async = true;

    document.body.appendChild(script);
    // document.body.appendChild(coinScript);

    return () => {
      document.body.removeChild(script);
      // document.body.removeChild(coinScript);
    };
  });

  useEffect(() => {
    // getEmailApiKey();
  }, []);

  //NOTE: You may want to check if this is a "Free" subscriber before showing the Chargebee widget
  // Leaving this code here for now, just a placeholder for that "Free" check.
  const getEmailApiKey = async () => {
    try {
      let response = await Api.getDataWithoutAuth(
        ApiRoutes.getApiCredentials('SENDGRID')
      );
      setSendgrid(response);
      if (response.apiKey) {
        setApiKey(response.apiKey);
      }
    } catch (err) {
      console.log('No API Key Found!');
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Card>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              Subscription Management
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>
              {chargebeeScriptLoaded ? (
                <Button w="100%" data-cb-type="portal">
                  Check My Subscription
                </Button>
              ) : (
                <p>
                  We could not load the subscription management button. Please
                  disable any VPN or browser extensions that might be blocking
                  the script and try again. VPNs are the most likely cause of
                  this.
                </p>
              )}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Subscription;
