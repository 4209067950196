import { Formik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { getCustomerInfo } from '../../redux/customer/actions';
import * as Api from '../../utils/api';

const Preferences = () => {
  const [loading, setLoading] = useState(false);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const ValidationSchema = Yup.object().shape({});
  const customerState = useSelector((state) => state.Customer);
  const orgPrefs = useSelector(
    (state) => customerState.info.organizations[0].preferences
  );
  const orgSubscription = useSelector(
    (state) => customerState.info.organizations[0].subscriptionType
  );

  /**
   * updateOrganizationPreferenceRoute handles
   * @param {*} value
   * @param {*} action
   */
  const handleOrgPrefUpdate = async (value, action) => {
    let requestData = {};
    requestData['auth0Id'] = customerState.info.auth0Id;
    requestData['colorPrimaryHex'] = value.primaryColor;
    requestData['colorSecondaryHex'] = value.secondaryColor;
    requestData['urlLinkedin'] = value.urlLinkedin;
    requestData['urlFacebook'] = value.urlFacebook;
    requestData['urlTwitter'] = value.urlTwitter;
    requestData['urlInstagram'] = value.urlInstagram;
    setLoading(true);
    try {
      await Api.postData(
        ApiRoutes.updateOrganizationPreferenceRoute(),
        requestData
      );
      dispatch(getCustomerInfo(customerState.info.auth0Id)); // returns Json of Json. Should always be used after updating a customer or organization related object
      setLoading(false);
      notyf.open({
        type: 'success',
        message: 'Successfully updated organization preferences'
      });
    } catch (exception) {
      notyf.open({
        type: 'error',
        message: 'Unable to update organization preferences.'
      });
      setLoading(false);
    }
  };

  // for single sends to test demo rewards with self
  async function sendTestToAdmins() {
    sendDemos(2);
  }

  // for single sends to test demo rewards with self
  async function sendTestToSelf() {
    sendDemos(1);
  }

  /**
   * just could not get this to work anywhere else
   * @param {*} number
   */
  async function sendDemos(number) {
    setLoading(true);
    const url =
      process.env.REACT_APP_SERVER_URL +
      ApiRoutes.sendExampleRewards(customerState.info.auth0Id, number);
    try {
      const response = await Api.postUrlEncodedData(url);
      setLoading(false);
      notyf.open({
        type: 'success',
        message: response.message
      });
    } catch (error) {
      setLoading(false);
      console.error('Error: ', error);
      console.log('got response', response);
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Organization Preferences
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {orgSubscription === 'FREE' ? (
          <div className="alert alert-info">
            Only paid plans can update these. Please upgrade if you want to
            update the rewards notification email that your respondants get.
          </div>
        ) : null}
        {loading && <Loader />}
        <Formik
          // org prefs should always show FynCom or Customer's depending on subscription type
          initialValues={{
            primaryColor: orgPrefs ? orgPrefs.colorPrimaryHex : '',
            secondaryColor: orgPrefs ? orgPrefs.colorSecondaryHex : '',
            urlLinkedin: orgPrefs ? orgPrefs.urlLinkedin : '',
            urlFacebook: orgPrefs ? orgPrefs.urlFacebook : '',
            urlTwitter: orgPrefs ? orgPrefs.urlTwitter : '',
            urlInstagram: orgPrefs ? orgPrefs.urlInstagram : ''
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, action) => {
            handleOrgPrefUpdate(values, action);
          }}
        >
          {(formikProps) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid
            } = formikProps;
            return (
              <Form className="mx-5" onSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3">
                      <Form.Label>Primary Color</Form.Label>
                      <Form.Control
                        id="primaryColor"
                        type="text"
                        name="primaryColor"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.primaryColor && !!errors.primaryColor
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.colorPrimaryHex)
                            : values.primaryColor
                        }
                        placeholder="Enter Primary Brand Color"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.primaryColor}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Secondary Color</Form.Label>
                      <Form.Control
                        id="secondaryColor"
                        type="text"
                        name="secondaryColor"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.secondaryColor && !!errors.secondaryColor
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.colorSecondaryHex)
                            : values.secondaryColor
                        }
                        placeholder="Enter Secondary Brand Color"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.secondaryColor}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>LinkedIn URL</Form.Label>
                      <Form.Control
                        id="urlLinkedin"
                        type="text"
                        name="urlLinkedin"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.urlLinkedin && !!errors.urlLinkedin
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.urlLinkedin)
                            : values.urlLinkedin
                        }
                        placeholder="Enter your Brand's LinkedIn URL here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.urlLinkedin}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Facebook URL</Form.Label>
                      <Form.Control
                        id="urlFacebook"
                        type="text"
                        name="urlFacebook"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.urlFacebook && !!errors.urlFacebook
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.urlFacebook)
                            : values.urlFacebook
                        }
                        placeholder="Enter your Brand's Facebook URL here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.urlFacebook}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Twitter URL</Form.Label>
                      <Form.Control
                        id="urlTwitter"
                        type="text"
                        name="urlTwitter"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.urlTwitter && !!errors.urlTwitter
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.urlTwitter)
                            : values.urlTwitter
                        }
                        placeholder="Enter your Brand's Twitter URL here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.urlTwitter}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Instagram URL</Form.Label>
                      <Form.Control
                        id="urlInstagram"
                        type="text"
                        name="urlInstagram"
                        isInvalid={
                          orgSubscription === 'FREE'
                            ? false
                            : touched.urlInstagram && !!errors.urlInstagram
                        }
                        value={
                          orgSubscription === 'FREE'
                            ? (values.name = orgPrefs.urlInstagram)
                            : values.urlInstagram
                        }
                        placeholder="Enter your Brand's Instagram URL here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.urlInstagram}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-2 mr-1" // Add margin-right
                      disabled={!isValid}
                    >
                      Save Preferences
                    </Button>
                  </Col>
                  <Col>
                    <Card className="outlined-card">
                      {/* Add a Card component to group the buttons and text */}
                      <Row>
                        <Col>
                          <p>Send Example Email Rewards to:</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              sendTestToSelf();
                            }}
                          >
                            Yourself
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {
                              sendTestToAdmins();
                            }}
                          >
                            Team Admins
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default Preferences;
