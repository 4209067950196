import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ApiRoutes } from '../../constants/api-routes';
import NotyfContext from '../../contexts/NotyfContext';
import { getData, postData } from '../../utils/api';

const MarketingEmail = (props) => {
  const {
    campaigns,
    marketingTemplates,
    setLoading,
    showDraftAlert,
    hideDraftAlert
  } = props;
  const customerState = useSelector((state) => state.Customer);
  const notyf = useContext(NotyfContext);
  const [emailDraft, setEmailDraft] = useState({});
  const [templateId, setTemplateId] = useState();
  const [campaignId, setCampaignId] = useState();
  const [kubikDataFlag, setKubikDataFlag] = useState(false);
  const [leadFormId, setLeadFormId] = useState();

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.code === 'KeyK' && event.shiftKey && event.ctrlKey) {
        setKubikDataFlag(!kubikDataFlag);
      }
    });

    return () => {
      hideDraftAlert();
    };
  }, [kubikDataFlag]);

  const handleTemplateChange = async (e) => {
    const templateId = e.target.value;
    try {
      const { organizationId } = customerState.info.organizations[0];
      setLoading(true);
      setTemplateId(templateId);
      const draftDetail = await getData(
        ApiRoutes.getMarketingTemplateDetail(organizationId, templateId)
      );
      setEmailDraft(draftDetail);

      if (draftDetail && !draftDetail.readyToSend) {
        showDraftAlert(draftDetail.message);
      } else {
        hideDraftAlert();
      }
    } catch (error) {
      notyf.open({
        type: 'error',
        message: 'Unable to fetch template details.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      const { organizationId } = customerState.info.organizations[0];
      setLoading(true);
      let request;

      if (!kubikDataFlag) {
        request = {
          campaignId,
          organizationId,
          customerId: customerState.info.customerId,
          templateId
        };
      } else {
        request = {
          campaignId,
          organizationId,
          customerId: customerState.info.customerId,
          templateId,
          leadFormId
        };
      }

      if (!kubikDataFlag) {
        await postData(ApiRoutes.sendTemplateEmailRoute(), request);
      } else {
        await postData(ApiRoutes.getKDEmailMarket(), request);
      }
      notyf.open({
        type: 'success',
        message: 'Successfully sent the marketing email(s).'
      });
    } catch (error) {
      notyf.open({
        type: 'error',
        message: 'Unable to send the marketing email(s).'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Rewards Campaign</Form.Label>
        <Form.Select
          id="campaign"
          name="campaign"
          onChange={(e) => {
            setCampaignId(e.target.value);
          }}
          value={campaignId}
        >
          <option value="" key="option-emtpy">
            Select rewards campaign
          </option>
          {campaigns.map((campaign) => (
            <option
              value={campaign.campaignId}
              key={`option-${campaign.campaignId}`}
            >
              {campaign.campaignName +
                '\t(reward: $' +
                campaign.rewardPerResponse +
                ')'}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Sendgrid Draft Template</Form.Label>
        <Form.Select
          id="templateId"
          name="templateId"
          onChange={handleTemplateChange}
        >
          <option>Select template</option>
          {marketingTemplates.map((template) => (
            <option value={template.id} key={`template-option-${template.id}`}>
              {template.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {kubikDataFlag ? (
        <Form.Group className="mb-3">
          <Form.Label>Lead Form Id</Form.Label>
          <Form.Control
            id="lead-form-customerId"
            type="text"
            name="leadFormId"
            placeholder="Enter Lead Form"
            onChange={(e) => {
              setLeadFormId(e.target.value);
            }}
          />
        </Form.Group>
      ) : (
        ''
      )}
      {emailDraft.subject && (
        <Form.Group className="mb-3">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="subject"
            name="subject"
            value={emailDraft.subject}
            disabled
          />
        </Form.Group>
      )}
      <div className="horizontal-center">
        <Button
          variant="primary"
          className="form-btn"
          disabled={!emailDraft.readyToSend || !campaignId}
          type="button"
          onClick={handleSendEmail}
        >
          Send email
        </Button>
      </div>
    </>
  );
};

export default MarketingEmail;
