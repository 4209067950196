import React, { useState, useContext, useEffect } from 'react';
import {
  Alert,
  Container,
  Modal,
  Button,
  Form,
  Accordion,
  Card,
  Row,
  Col,
  ProgressBar
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import Statistics from './Statistics';
import StarterModal from './starterModal';
import SendgridModal from './sendgridModal';
import * as Api from '../../../utils/api';
import EmailMsg from '../../../assets/img/email-msg.png';
import ProfileOrgIcon from '../../../assets/img/org-dashboard-logo.png';
import SendgridIcon from '../../../assets/img/sendgrid.svg';
import EmailFwdIcon from '../../../assets/img/email-forwarding.png';
import FirstRewardIcon from '../../../assets/img/first-reward.png';
import { getData } from '../../../utils/api';
import { ApiRoutes } from '../../../constants/api-routes';
import NotyfContext from '../../../contexts/NotyfContext';
import { getCustomerInfo } from '../../../redux/customer/actions';
import { Pie } from 'react-chartjs-2';
import { check } from 'prettier';

const ProfileUpdationUI = () => {
  const customerState = useSelector((state) => state.Customer);
  const [showStarterModal, setStarterModal] = useState(false);
  const [showSendgridModal, setSendgridModal] = useState(false);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(true);
  const notify = useContext(NotyfContext);
  const [isOrgUpdated, setOrgFlag] = useState(false);
  const [isEmailVerified, setEmailVerifiedFlag] = useState(false);
  const [isEmailForwardingSetup, setEmailForwardingFlag] = useState(false);
  const [isFirstRewardGiven, setFirstRewardGivenFlag] = useState(false);
  const [isApiKeyUpdated, setApiKeyFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Profile Completion:' + getPercent() + '%');
  }, [count]);

  useEffect(() => {
    if (
      customerState.info.organizations &&
      customerState.info.organizations[0] &&
      customerState.info.organizations[0].organizationName === 'Default org'
    ) {
      setOrgFlag(false);
    } else {
      setOrgFlag(true);
      setCount(count + 1);
      console.log('Org Name Updated!');
    }
  }, [isOrgUpdated]);

  /**
   * This SHOULD handle instances where an organization is not yet available for new customers
   */
  useEffect(() => {
    if (customerState.info.organizations == undefined) {
      setOrgFlag(false);
    }
    if (customerState.info.emailVerified) {
      setEmailVerifiedFlag(true);
      setCount(count + 1);
      console.log('Email Verified!');
    }
  }, [isEmailVerified]);

  /**
   * NOTE: some legacy customers may not have preferences set, so this can break for them.
   */
  useEffect(() => {
    // if (customerState.info.organizations == undefined) {
    //   setOrgFlag(false);
    // }
    if (customerState.info.preferences.setupForwardingEmail) {
      setCount(count + 1);
      console.log('Email Forwarding Setup Completed!');
    }
  }, [isEmailForwardingSetup]);

  /**
   * Counts the "First Reward Given" card
   */
  useEffect(() => {
    if (customerState.info.preferences.firstRewardOfferCompleted) {
      setCount(count + 1);
      console.log('First Rewards Given!');
    }
  }, [isFirstRewardGiven]);

  /**
   * For counting Sendgrid API key towards completion ratio
   */
  useEffect(() => {
    if (isApiKeyUpdated) {
      setCount(count + 1);
      console.log('API Key Found!');
    }
  }, [isApiKeyUpdated]);

  const getPercent = () => {
    let totalPercentage = Math.round((count / 5) * 100);
    return totalPercentage;
  };

  const updateOrgName = (value) => {
    setOrgFlag(value);
  };

  const updateApiKey = (value) => {
    setApiKeyFlag(value);
  };

  const toggleStarterModal = () => {
    setStarterModal(!showStarterModal);
  };

  const toggleSendgridModal = () => {
    setSendgridModal(!showSendgridModal);
  };

  const checkSendgridApiKey = async () => {
    try {
      let response = await Api.getDataWithoutAuth(
        ApiRoutes.getApiCredentials('SENDGRID')
      );
      if (response.apiKey) {
        setApiKeyFlag(true);
      }
    } catch (err) {
      setApiKeyFlag(false);
    }
  };

  /**
   * Currently used to check email verified status
   */
  const fetchOrg = async () => {
    setLoading(true);
    try {
      let response = await getData(
        ApiRoutes.getCustomerAccountInfoRoute(customerState.info.auth0Id)
      );
      setLoading(false);
      if (response.emailVerified) {
        notify.open({
          type: 'success',
          message: 'Your email has been successfully verified!'
        });
        dispatch(getCustomerInfo(customerState.info.auth0Id));
      } else {
        notify.open({
          type: 'error',
          message: 'Your email has not been verified yet.'
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**
   * This should only be called when button is clicked....
   * Checks to see if customer preference has a record of email forwarding setup
   * Customer's preferences can also be accessed through customerState.info.preferences
   */
  const handleEmailFwdStatus = async () => {
    setLoading(true);
    try {
      let response = await getData(
        ApiRoutes.getCustomerPreferenceRoute(customerState.info.auth0Id)
      );
      setLoading(false);
      console.log('EmailFwdPre:' + JSON.stringify(response.customerPreference));
      if (response.customerPreference['setupForwardingEmail']) {
        setEmailForwardingFlag(true);
        notify.open({
          type: 'success',
          message: 'Your email forwarding has been successfully set up!'
        });
      } else {
        setEmailForwardingFlag(false);
        console.log(
          'Email Forwarding setup = ' +
            response.customerPreference['setupForwardingEmail']
        );
        notify.open({
          type: 'error',
          message:
            'Your email forwarding has not been set up yet. Try the help pages to setup your simple or marketing/basic emails'
        });
      }
    } catch (err) {
      //unsure if flags are needed here.....
      setLoading(false);
      console.log(err);
    }
  };

  // one time check
  const checkEmailFwdStatus = async () => {
    try {
      let response = await getData(
        ApiRoutes.getCustomerPreferenceRoute(customerState.info.auth0Id)
      );
      if (response.customerPreference['setupForwardingEmail']) {
        setEmailForwardingFlag(true);
      } else {
        setEmailForwardingFlag(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * This should only be called when button is clicked....
   * Checks status of "first reward given"
   *
   */
  const handleCheckFirstRewardOfferCompletedStatus = async () => {
    setLoading(true);
    try {
      let response = await getData(
        ApiRoutes.getCustomerPreferenceRoute(customerState.info.auth0Id)
      );
      setLoading(false);
      console.log('Pref rewd :' + JSON.stringify(response.customerPreference));
      if (response.customerPreference['firstRewardOfferCompleted']) {
        setFirstRewardGivenFlag(true);
        notify.open({
          type: 'success',
          message: 'Your first reward has been given! Well done!'
        });
        dispatch(getCustomerInfo(customerState.info.auth0Id));
      } else {
        setFirstRewardGivenFlag(false);
        notify.open({
          type: 'error',
          message:
            'Your first reward has not been given. Try an email or form reward.'
        });
      }
    } catch (err) {
      //unsure if flags are needed here.....
      setLoading(false);
      console.log(err);
    }
  };

  // one time check
  const checkFirstRewardOfferCompletedStatus = async () => {
    try {
      let response = await getData(
        ApiRoutes.getCustomerPreferenceRoute(customerState.info.auth0Id)
      );
      if (response.customerPreference['firstRewardOfferCompleted']) {
        setFirstRewardGivenFlag(true);
        // when people return... you may want to update this somehow...? Splashy screen animation? maybe?
        dispatch(getCustomerInfo(customerState.info.auth0Id));
      } else {
        setFirstRewardGivenFlag(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Ensure that these check methods are only called once on runtime
   */
  if (count == 0) {
    checkSendgridApiKey();
    checkEmailFwdStatus();
    checkFirstRewardOfferCompletedStatus();
  }

  /**
   * Not planning to use this externally - too minute - may use internally
   */
  const getFirstRewardOfferSentStatus = async () => {
    try {
      let response = await getData(
        ApiRoutes.getCustomerPreferenceRoute(customerState.info.auth0Id)
      );
      console.log('Prefs sent:' + JSON.stringify(response.customerPreference));
      if (response.customerPreference['firstRewardOfferSent']) {
        notify.open({
          type: 'success',
          message: 'You have sent your first email reward offer!'
        });
        dispatch(getCustomerInfo(customerState.info.auth0Id));
      } else {
        notify.open({
          type: 'error',
          message: 'You have not sent your first offer yet'
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const data = {
    labels: ['Profile Completed', 'Profile Remaining'],
    datasets: [
      {
        data: [getPercent(), 100 - getPercent()],
        backgroundColor: ['#4A90E2', '#ffffff'],
        borderWidth: 2,
        borderColor: '#ffffff'
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    legend: {
      display: false
    }
  };

  return (
    <Row>
      <div className="pb-4">
        {getPercent() && getPercent() >= 100 ? (
          <div></div>
        ) : (
          <div>
            <div className="row" id="onboardingToDo">
              <h3>
                To do list{' '}
                <button
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  {show ? 'Hide' : 'Show'}
                </button>
              </h3>

              <div className={`row ${show ? '' : 'dn'}`}>
                {/* Verify your email Card*/}
                {!customerState.info.emailVerified && (
                  <Col className="d-flex">
                    <div className="position-relative w-100">
                      <Card className="flex-fill flex-row ">
                        <Card.Body className="verify-onboarding-steps">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              fetchOrg();
                            }}
                          >
                            <div className="text-center">
                              <img
                                src={EmailMsg}
                                className="profile-update-icon"
                              />
                            </div>
                            <div className="text-center" id="Email Tab">
                              Verify your email
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )}

                {/* Add Organization card */}
                {!isOrgUpdated && (
                  <Col className="d-flex">
                    <div className="position-relative w-100">
                      <Card className="flex-fill flex-row">
                        <Card.Body className="verify-onboarding-steps">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              toggleStarterModal();
                            }}
                          >
                            <div className="text-center">
                              <img
                                src={ProfileOrgIcon}
                                className="profile-update-icon"
                              />
                            </div>
                            <div className="text-center" id="Organization Tab">
                              Add your organization
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )}

                {/* setup email forwarding card */}
                {!customerState.info.preferences.setupForwardingEmail && (
                  <Col className="d-flex">
                    <div className="position-relative w-100">
                      <Card className="flex-fill flex-row">
                        <Card.Body className="verify-email-forwarding">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              handleEmailFwdStatus();
                            }}
                          >
                            <div className="text-center">
                              <img
                                src={EmailFwdIcon}
                                className="profile-update-icon"
                              />
                            </div>
                            <div className="text-center" id="Email FWD Tab">
                              Setup Email Forwarding
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )}

                {/* first reward offer completed card */}
                {!customerState.info.preferences.firstRewardOfferCompleted && (
                  <Col className="d-flex">
                    <div className="position-relative w-100">
                      <Card
                        className="flex-fill flex-row"
                        id="update-profile-org"
                      >
                        <Card.Body className="verify-onboarding-steps">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckFirstRewardOfferCompletedStatus();
                            }}
                          >
                            <div className="text-center">
                              <img
                                src={FirstRewardIcon}
                                className="profile-update-icon"
                              />
                            </div>
                            <div className="text-center" id="Reward Tab">
                              First Reward Given?
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )}

                {/* Sendgrid API Key card */}
                {!isApiKeyUpdated && (
                  <Col className="d-flex">
                    <div
                      className="position-relative w-100"
                      id="update-profile-sendgrid-api"
                    >
                      <Card className="flex-fill flex-row">
                        <Card.Body className="verify-onboarding-steps">
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSendgridModal();
                            }}
                          >
                            <div className="text-center">
                              <img
                                src={SendgridIcon}
                                className="profile-update-icon"
                              />
                            </div>
                            <div className="text-center" id="SendGrid Tab">
                              Add Sendgrid API key
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )}

                {/* Pie Chart Updates */}
                <Col className="d-flex" id="org">
                  <div
                    className="position-relative w-100"
                    id="progress-pie-chart"
                  >
                    <Card className="flex-fill flex-row">
                      <Card.Body className="onboarding-completion-chart">
                        {/* <div className="col-2 " id="progress-pie-chart"> */}
                        <div className="chart profile_chart">
                          <Pie data={data} options={options} />
                        </div>
                        <div className="text-center starter_profile_text">
                          {getPercent() + '%'} of your profile has been updated
                        </div>
                        {/* </div> */}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </div>
            </div>
            <StarterModal
              show={showStarterModal}
              toggle={toggleStarterModal.bind()}
              updateOrgStatus={updateOrgName.bind()}
            />
            <SendgridModal
              show={showSendgridModal}
              toggle={toggleSendgridModal.bind()}
              updateApiKeyStatus={updateApiKey.bind()}
            />
          </div>
        )}
      </div>
    </Row>
  );
};

const Default = () => {
  const tutorState = useSelector((state) => state.Tutor);
  const [showStarterModal, setStarterModal] = useState(false);
  const [showSendgridModal, setSendgridModal] = useState(false);
  const toggleStarterModal = () => {
    setStarterModal(!showStarterModal);
  };
  const toggleSendgridModal = () => {
    setSendgridModal(!showSendgridModal);
  };

  const updateOrgName = (value) => {
    setOrgFlag(value);
  };

  useEffect(() => {
    if (tutorState.isActive && tutorState.tutorStep === 2) {
      setStarterModal(true);
    } else {
      setStarterModal(false);
    }
    if (tutorState.isActive && tutorState.tutorStep === 3) {
      setSendgridModal(true);
    } else {
      setSendgridModal(false);
    }
  }, [tutorState]);

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Container fluid className="p-0">
        <Header />
        <ProfileUpdationUI />
        <Statistics />
        <StarterModal
          show={tutorState.isActive && tutorState.tutorStep === 2}
          toggle={toggleStarterModal.bind()}
          updateOrgStatus={updateOrgName.bind()}
        />
        <SendgridModal
          show={tutorState.isActive && tutorState.tutorStep === 3}
          toggle={toggleSendgridModal.bind()}
        />
      </Container>
    </React.Fragment>
  );
};

export default Default;
