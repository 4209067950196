import { MessageSquare } from 'react-feather';
import React, { useState, useRef, ReactDOM, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startTutor, stopTutor } from '../../redux/tutor/actions';
import { useNavigate } from 'react-router-dom';
import { setStepCount, startDev, stopDev } from '../../redux/tutor/actions';
import { X } from 'react-feather';
import WelcomScreenModal from './welcomeScreenModal';
import { Modal, Button } from 'react-bootstrap';
import Fyncall from './fyncall';

function TutorModule() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tutorState = useSelector((state) => state.Tutor);
  const totalStep = 13;
  const [showWelcomeScreen, toggleWelcomeScreen] = useState(false);
  const toggleClose = () => toggleWelcomeScreen(false);
  const [showFynModal, setFynModal] = useState(false);

  useEffect(() => {
    if (tutorState.isActive) {
      switch (tutorState.tutorStep) {
        case 1:
          toggleWelcomeScreen(true);
          break;
        case 2:
          toggleWelcomeScreen(false);
          break;
        case 3:
          break;
        case 4:
          step4();
          break;
        case 5:
          document
            .querySelector('nav')
            .style.setProperty('display', 'inline-block');
          step5();
          break;
        case 6:
          // if (document.querySelector('nav')) {
          //   document
          //     .querySelector('nav')
          //     .style.setProperty('display', 'inline-block');
          // }
          if (document.getElementById('tutor_instruction_id')) {
            console.log('destroy element');
            document.getElementById('tutor_instruction_id').remove();
          }
          let campaignForm = setTimeout(() => {
            if (document.getElementById('create_btn')) {
              document
                .querySelector('.sidebar')
                .style.setProperty('z-index', 1000);
              step6();
              clearTimeout(campaignForm);
            }
          }, 1000);

          break;
        case 7:
          if (document.getElementById('tutor_instruction_id')) {
            console.log('destroy element');
            document.getElementById('tutor_instruction_id').remove();
          }
          document
            .querySelector('.sidebar')
            .style.setProperty('z-index', '2500');
          let maskElement = document.createElement('div');
          maskElement.setAttribute('id', 'maskElement-1');
          maskElement.classList.add('tutor_sidebar_mask');
          let sidebarElement = document.getElementById('sidebar-item-0');
          sidebarElement.style.setProperty('position', 'relative');
          if (!document.getElementById(`maskElement-1`)) {
            sidebarElement.appendChild(maskElement);
          }

          step7();
          break;
        case 8:
          document
            .querySelector('#sidebar-item-2 a')
            .removeEventListener('click', () => {
              console.log('remove event listner');
            });
          fadeSideBar();
          step8();
          break;
        case 9:
          if (document.getElementById('tutor_instruction_id')) {
            document.getElementById('tutor_instruction_id').remove();
          }
          step9();
          break;
        case 10:
          if (document.getElementById('tutor_instruction_id')) {
            document.getElementById('tutor_instruction_id').remove();
          }

          if (document.querySelector('.email-popover')) {
            document.querySelector('.email-popover').remove();
          }
          document
            .querySelector('.sidebar')
            .style.setProperty('z-index', '2600');
          setSidebar();
          // navigate('/campaign/transaction');
          step10();
          break;
        case 11:
          if (document.getElementById('tutor_instruction_id')) {
            document.getElementById('tutor_instruction_id').remove();
          }
          document.querySelector('.sidebar').style.setProperty('z-index', 1000);
          step11();
          break;
        case 12:
          if (document.getElementById('tutor_instruction_id')) {
            document.getElementById('tutor_instruction_id').remove();
          }
          step12();
          break;
        case 13:
          if (document.getElementById('tutor_instruction_id')) {
            document.getElementById('tutor_instruction_id').remove();
          }

          if (document.getElementById('tutor_campaign_id')) {
            document.getElementById('tutor_campaign_id').remove();
          }
          finalStep();
          break;

        default:
          break;
      }
    }
  }, [tutorState.tutorStep]);

  useEffect(() => {
    window.addEventListener(
      'keydown',
      function (event) {
        if (event.code === 'KeyF' && event.shiftKey && event.ctrlKey) {
          if (!tutorState.dev) {
            dispatch(startDev());
            navigate('/');
          } else {
            dispatch(stopDev());
            navigate('/');
          }
        }
      },
      true
    );
  }, []);

  const handleswitch = () => {
    dispatch(startTutor());
    dispatch(setStepCount(1));
    navigate('/');
  };

  const fadeSideBar = () => {
    document.querySelector('.sidebar').style.setProperty('z-index', 1000);
  };

  const getElement = (id) => {
    return document.getElementById(id);
  };

  const setNavEventHandler = () => {
    const leftNavTimer = setTimeout(() => {
      console.log(document.getElementById('tutor_left_nav'));
      if (getElement('tutor_left_nav')) {
        document
          .getElementById('tutor_left_nav')
          .addEventListener('click', leftNavHandle);
        clearTimeout(leftNavTimer);
      }
    }, 1000);

    let rightNavTimer = setTimeout(() => {
      if (document.getElementById('tutor_right_nav')) {
        document
          .getElementById('tutor_right_nav')
          .addEventListener('click', rightNavHandle);
        clearTimeout(rightNavTimer);
      }
    }, 1000);
  };

  const setExitClickHandler = () => {
    let exitTimer = setTimeout(() => {
      if (document.getElementById('exitDemo')) {
        document.getElementById('exitDemo').addEventListener('click', exitDemo);
        clearTimeout(exitTimer);
      }
    }, 1000);
  };

  const step4 = () => {
    if (document.getElementById('tutor_instruction_id')) {
      document.getElementById('tutor_instruction_id').remove();
    }
    navigate('/dashboard');
    let dashboardTimer = setTimeout(() => {
      if (
        document.querySelectorAll('#org .card') &&
        document.querySelectorAll('#org .card')[0]
      ) {
        let walletElement = document.getElementById('tutor_wallet');
        if (walletElement) {
          walletElement.classList.add('tutor_wallet_css');
        }
        document
          .getElementById('available_Promotional_bal')
          .style.setProperty('background', '#ffffff');
        clearTimeout(dashboardTimer);
      }
    }, 1000);

    let mainComponent = document.getElementById('main');
    if (mainComponent) {
      mainComponent.classList.add('tutor_main_css');
    }

    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('side-bar-notes');
    setNavEventHandler();
    setExitClickHandler();
    let sideBarCampignElement = document.getElementById(
      'available_Promotional_bal'
    );

    if (sideBarCampignElement == null) {
      dispatch(stopTutor());
    } else {
      sideBarCampignElement.style.setProperty('position', 'relative');
      sideBarCampignElement.appendChild(popoverElement);
    }

    if (document.getElementById('create_sidebar_popup')) {
      document.getElementById('create_sidebar_popup').remove();
    }

    clearSideBar();
  };

  const setSidebar = () => {
    for (let i = 1; i <= 5; i++) {
      let maskElement = document.createElement('div');
      maskElement.setAttribute('id', 'maskElement-' + i);
      let sidebarElement = document.getElementById('sidebar-item-' + i);
      sidebarElement.style.setProperty('position', 'relative');
      if (!document.getElementById(`maskElement-${i}`)) {
        sidebarElement.appendChild(maskElement);
      }
      maskElement.classList.add('tutor_sidebar_mask');
    }
  };

  const clearSideBar = () => {
    for (let i = 0; i <= 5; i++) {
      let maskElement = document.querySelector('#maskElement-' + i);
      if (maskElement) {
        maskElement.classList.remove('tutor_sidebar_mask');
      }
    }
  };

  const step5 = () => {
    if (document.getElementById('tutor_instruction_id')) {
      document.getElementById('tutor_instruction_id').remove();
    }

    document.querySelector('.sidebar').style.setProperty('z-index', '3000');

    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('side-bar-campaign-notes');
    popoverElement.removeEventListener('click', (e) => {
      e.preventDefault();
    });
    setSidebar();
    setExitClickHandler();
    let sideBarCampignElement = document.getElementById('sidebar-item-1');
    if (sideBarCampignElement) {
      sideBarCampignElement.style.setProperty('position', 'relative');
      sideBarCampignElement.appendChild(popoverElement);
      let campaignElement = document.querySelector('#sidebar-item-1 a');
      campaignElement.addEventListener('click', (e) => {
        if (document.getElementById('tutor_instruction_id')) {
          document.getElementById('tutor_instruction_id').remove();
        }
        createCapaignPopup('createShowPopup');
      });
    }
  };

  const removeNavoption = () => {
    document
      .getElementById('tutor_left_nav')
      .style.setProperty('display', 'none');
    document
      .getElementById('tutor_right_nav')
      .style.setProperty('display', 'none');
  };

  const createCapaignPopup = (createflag = undefined) => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    if (createflag) {
      popoverElement.innerHTML = instructionPanel(
        'side-bar-create-campaign',
        createflag
      );
    } else {
      popoverElement.innerHTML = instructionPanel('side-bar-create-campaign');
    }

    popoverElement.addEventListener('click', (e) => {
      e.preventDefault();
    });

    // setNavEventHandler();
    setExitClickHandler();
    let campaingSideBar = 'sidebar-item-Campaign-Create';
    let sideBarCampignElement = document.getElementById(campaingSideBar);
    sideBarCampignElement.style.setProperty('position', 'relative');
    sideBarCampignElement.appendChild(popoverElement);
    let campaignElement = document.querySelector(
      '#sidebar-item-Campaign-Create a'
    );

    campaignElement.addEventListener('click', () => {
      dispatch(setStepCount(tutorState.tutorStep + 1));
    });
  };

  /**
   * This navigation is handled by Dispatch code in
   * src\pages\campaign\Create\index.jsx
   */
  const step6 = () => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('create-compagin-form-tutor');
    setExitClickHandler();
    let createCampaignForm = document.querySelector(
      '.create-campaign-from-container'
    );
    createCampaignForm.classList.add('create-campaign-from-container');
    let createButtonElement = document.getElementById('create_btn');
    createButtonElement.style.setProperty('position', 'relative');
    createButtonElement.appendChild(popoverElement);
  };

  const createTutor = (tutorClass) => {
    if (document.getElementById('tutor_instruction_id')) {
      document.getElementById('tutor_instruction_id').remove();
    }
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel(tutorClass);
    return popoverElement;
  };

  const step7 = () => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('first-reward-email', 1);

    document.getElementById('sidebar-item-2').appendChild(popoverElement);

    setEmailSideBar();
    setExitClickHandler();

    document
      .querySelector('#sidebar-item-2 a')
      .addEventListener('click', () => {
        if (document.getElementById('tutor_instruction_id')) {
          document.getElementById('tutor_instruction_id').remove();
        }

        setTimeout(() => {
          if (document.getElementById('email-card-container')) {
            document
              .getElementById('email-card-container')
              .classList.add('tutor_email-card-container');
          }

          if (document.getElementById('tutor-email-container')) {
            document
              .getElementById('tutor-email-container')
              .classList.add('tutor-email-body-css');
          }

          if (document.querySelector('.card-body')) {
            document
              .querySelector('.card-body')
              .style.setProperty('background', '#73878c36');
          }

          if (document.querySelector('.email-type__input')) {
            document
              .querySelector('.email-type__input')
              .style.setProperty('background', '#fff');
          }

          rightNavHandle();
        }, 1000);
      });
  };

  const step8 = () => {
    let popoverElement = createTutor('tutor-basic-email-body');
    document.querySelector('.sidebar').style.setProperty('z-index', '3000');
    setNavEventHandler();
    setExitClickHandler();

    let sideBarEmailElement = document.getElementById('sidebar-item-2');
    sideBarEmailElement.removeEventListener('click', () => {
      if (document.getElementById('tutor_instruction_id')) {
        document.getElementById('tutor_instruction_id').remove();
      }
    });

    if (document.getElementsByClassName('email-type__input')[0]) {
      document
        .getElementsByClassName('email-type__input')[0]
        .style.setProperty('background', '#73878c36');
    }

    if (document.getElementById('tutor-email-container')) {
      document
        .getElementById('tutor-email-container')
        .classList.remove('tutor-email-body-css');
    }

    if (document.getElementById('quill-editor')) {
      document.getElementById('quill-editor').appendChild(popoverElement);
    }
  };

  const setEmailSideBar = () => {
    let maskElement = document.querySelector('#maskElement-' + 1);
    if (maskElement) {
      maskElement.classList.remove('tutor_sidebar_mask');
      maskElement.parentElement.addEventListener('click', (e) => {
        step5();
      });
    }
  };

  /**
   * Example email reward shows up here.
   */
  const step9 = () => {
    let popoverElement = createTutor('tutor-basic-email-body');
    document.querySelector('.sidebar').style.setProperty('z-index', '3000');
    setNavEventHandler();
    setExitClickHandler();

    let sideBarEmailElement = document.getElementById('sidebar-item-2');
    sideBarEmailElement.removeEventListener('click', () => {
      if (document.getElementById('tutor_instruction_id')) {
        document.getElementById('tutor_instruction_id').remove();
      }
    });

    if (document.getElementsByClassName('email-type__input')[0]) {
      document
        .getElementsByClassName('email-type__input')[0]
        .style.setProperty('background', '#73878c36');
    }

    if (document.getElementById('tutor-email-container')) {
      document
        .getElementById('tutor-email-container')
        .classList.remove('tutor-email-body-css');
    }

    if (document.getElementById('quill-editor')) {
      let emailElementContainer = document.createElement('div');
      emailElementContainer.innerHTML = `<div class="email-popover">
      <div id="tutor_email_image_id">
      <img src="/ReplyGif.gif" class="email-gif-css" /></div>
    </div>`;

      document
        .getElementById('quill-editor')
        .appendChild(emailElementContainer);

      emailElementContainer.appendChild(popoverElement);
    }
  };

  const step10 = () => {
    // let popoverElement = createTutor('side-bar-campaign-notes');
    // document.querySelector('.sidebar').style.setProperty('z-index', '3000');
    // setSidebar();
    // setNavEventHandler();
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('side-bar-campaign-notes');
    if (document.getElementById('sidebar-item-Campaign-Transactions')) {
      document
        .getElementById('sidebar-item-Campaign-Transactions')
        .appendChild(popoverElement);
    }
    let sidebarTransaction = document.querySelector(
      '#sidebar-item-Campaign-Transactions a'
    );

    if (sidebarTransaction) {
      sidebarTransaction.style.setProperty('position', 'relative');
      setExitClickHandler();

      sidebarTransaction.addEventListener('click', (e) => {
        if (document.getElementById('tutor_instruction_id')) {
          document.getElementById('tutor_instruction_id').remove();
        }
        rightNavHandle();
      });
    }
  };

  const step11 = () => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('tutor-transaction-1');
    popoverElement.removeEventListener('click', (e) => {
      e.preventDefault();
    });

    setNavEventHandler();
    setExitClickHandler();

    if (document.getElementById('transaction-id')) {
      document.getElementById('transaction-id').appendChild(popoverElement);
    }

    let transactionTimer = setTimeout(() => {
      if (document.getElementById('transaction-id')) {
        document.getElementById('transaction-id').appendChild(popoverElement);
        setNavEventHandler();
        clearTimeout(transactionTimer);
      }
    }, 1000);
  };

  const step12 = () => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = instructionPanel('tutor-transaction-mail');
    popoverElement.removeEventListener('click', (e) => {
      e.preventDefault();
    });

    if (document.getElementById('transaction-id')) {
      document.getElementById('transaction-id').appendChild(popoverElement);
    }

    let transactionTimer = setTimeout(() => {
      if (document.getElementById('transaction-id')) {
        let campaignPopupElement = document.createElement('div');
        campaignPopupElement.setAttribute('id', 'tutor_campaign_id');
        campaignPopupElement.innerHTML = `<div class="campaign-popover">
        <div id="tutor_campaign_id" class="position-relative">
        <img src="/reward-message-example.png" style="margin: auto"/></div>

      </div>`;

        document
          .getElementById('transaction-id')
          .appendChild(campaignPopupElement);

        document
          .getElementById('tutor_campaign_id')
          .appendChild(popoverElement);
        setNavEventHandler();

        clearTimeout(transactionTimer);
      }
    }, 1000);
  };

  const finalStep = () => {
    let popoverElement = document.createElement('div');
    popoverElement.setAttribute('id', 'tutor_instruction_id');
    popoverElement.innerHTML = finalStepPanel();
    // setNavEventHandler();
    setExitClickHandler();
    let createButtonElement = document.getElementById('transaction-id');
    if (createButtonElement) {
      createButtonElement.style.setProperty('position', 'relative');
      createButtonElement.appendChild(popoverElement);
    }
  };

  const handlePopup = () => {
    document
      .getElementById('side-bar-item-Campaign')
      .style.setProperty('position', 'relative');
    let popoverElement = document.createElement('div');
    popoverElement.innerHTML =
      '<div class="side-bar-notes">\
      <div class="d-flex"> \
      <div class="message-pointer"><</div>\
      <div class="message-box">\
      <div class="message_text_css">click here to check Campaign </div>\
    </div>\
      </div></div';
    document
      .getElementById('side-bar-item-Campaign')
      .appendChild(popoverElement);

    let campaignElement = document.getElementById('side-bar-item-Campaign');
    campaignElement.addEventListener('click', () => {});
  };

  const instructionPanel = (outerElementStyle, stepValue = undefined) => {
    let step = { header: '', content: '' };
    let arrowCss = 'tutor-arrow';
    let arrowVisibility = '';
    switch (tutorState.tutorStep) {
      case 4:
        step.header = 'Available Promotional Balance';
        step.content =
          'Here is a $5 promo credit to get you started. Until you add your own funds, keep your reward per response to $0.50 or less for the best experience.';
        arrowCss = 'tutor-arrow';
        break;
      case 5:
        if (stepValue) {
          step.header = 'Create Campaign';
          step.content = 'Click "Create" to make a Rewards Campaign.';
          arrowCss = 'arrow_step3';
        } else {
          step.header = 'Campaign';
          step.content =
            'Create a Reward Campaign first. Click "Campaign" to start.';
          arrowCss = 'arrow_step2';
        }
        arrowVisibility = 'display-v-none';
        break;
      case 6:
        step.header = 'Create';
        step.content =
          'Once you fill out these four required fields, click "Create" to finalize your campaign.';
        // arrowCss = 'arrow_step4';
        arrowCss = 'arrow_step6';
        arrowVisibility = 'display-v-none';
        break;
      // TODO the wording of Step 6 seems to get repeated twice - This is for the sidebar and for the 1st time we land on Email page.
      // Ideally, the wording of step 7 is used when the tutorModule is overing near the "Basic Email" selection
      case 7:
        step.header = 'Email';
        step.content =
          'Now that you have your first Reward Campaign, you can send an interactive rewards email. Click "Email" to continue.';
        arrowCss = 'arrow_step2';
        if (stepValue) {
          arrowVisibility = 'display-v-none';
        }
        break;
      case 8:
        step.header = 'Basic email';
        step.content =
          'Let\'s begin with a basic email, which can be sent to one person at a time. Send yourself an email through here. You can try "Marketing Emails" for mass email sends later.';
        arrowCss = 'arrow_step2';
        arrowVisibility = 'display-v-none';
        break;
      // TODO - Please ensure that customers cannot proceed to step 8 until they have successfully send themselves an email.
      case 9:
        step.header = 'FynMail Example';
        step.content =
          'Here is an example rewards email. You should have one from "rewards@fyncom.com". Please go to your email inbox and reply to that email. Return here after replaying.';
        // I ADDED TOO MUCH TEXT, BUT WANT THE LAST PART (LINK CLICKS) TO BE MENTIONED SOMEWHERE - PERHAPS IN A LATER STEP WHEN VISITNG THE REWARD TRANSACTIONS?
        //   'Here is an example rewards email. You should have one from "rewards@fyncom.com". Please go to your email inbox and reply to that email.\
        // Recipients MUST reply to this to get their rewards. Link clicks are optional - contact support@fyncom.com if interested in link clicks.';
        arrowCss = 'arrow_step2';
        break;
      case 10:
        step.header = 'Transactions';
        step.content =
          'Click here to check your Campaign Reward Transactions history.';
        arrowCss = 'arrow_step2';
        arrowVisibility = 'display-v-none';
        break;
      case 11:
        step.header = 'Campaign Reward Transactions';
        step.content =
          'Here, you can view the record of responses to your Reward Campaigns.';
        arrowCss = 'arrow_step2';
        break;
      case 12:
        step.header = 'Transaction';
        step.content =
          'Shortly after responding, respondents will receive an email like this \
                   from rewards@fyncom.com. Add your Sendgrid API key to send from your own email and select your own templates.';
        arrowCss = 'arrow_step2';
        break;
      default:
        break;
    }
    return `<div class="${outerElementStyle}">
 
    <div class="card border-success mb-3 instruction-card">
    <div class="${arrowCss}">
    </div>
    <div class="card-header bg-transparent border-success">
      <div class="d-inline ">
        <div
          class="d-inline"
        >
        <i class="fa-solid fa fa-chevron-left px-2 ${arrowVisibility}" id="tutor_left_nav" ></i>
        </div>
        <div class="d-inline">
        <i class="fa-solid fa fa-chevron-right px-2 ${arrowVisibility}" id="tutor_right_nav"></i>
        </div>
      </div>
      <div class="tutor-counter">
        ${tutorState.tutorStep}/${totalStep}	
      </div>
    </div>
    <div class="card-body text-success">
      <h5 class="card-title">${step.header}</h5>
      <p class="card-text tutor-text">${step.content}</p>
    </div>
    <div class="card-footer bg-transparent border-success">
      <div
        id="exitDemo"
        class="d-inline"
      >
        skip
      </div>
    </div>
  </div>
  
  </div>`;
  };

  const finalStepPanel = () => {
    return `<div class="tutor_final_step">
    <div class="card border-success mb-3 instruction-card">
    <div class="card-header bg-transparent border-success">
    </div>
    <div class="card-body text-success">
      <h5 class="card-title">Success</h5>
      <p class="card-text">You have successfully completed all the steps for sending basic FynMails!</p>
    </div>
    <div class="card-footer bg-transparent border-success">
      <button
        id="exitDemo"
        class="start_with_app_btn"
      >
         <span>Start Using FynCom</span>
         <span class="start_arrow">>></span>
      </button>
    </div>
  </div>
  </div>`;
  };

  const leftNavHandle = () => {
    if (tutorState.tutorStep > 1)
      dispatch(setStepCount(tutorState.tutorStep - 1));
  };
  const rightNavHandle = () => {
    if (tutorState.tutorStep + 1 <= totalStep)
      dispatch(setStepCount(tutorState.tutorStep + 1));
  };

  const exitDemo = () => {
    document.querySelector('.content').classList.remove('tutor-card-fade');
    if (document.getElementById('tutor_instruction_id')) {
      document.getElementById('tutor_instruction_id').remove();
    }
    dispatch(stopTutor());
    setShow(false);
    navigate('/');
  };

  const handleFynModalClose = () => {
    setFynModal(false);
  };

  console.log('Tutorial State:' + JSON.stringify(tutorState, null, 2));

  return (
    <React.Fragment>
      <div>
        {show && !tutorState.isActive ? (
          <div className="tutor-widget-position">
            <div className="tutor-widget-outer">
              <div className="tutor-widget-head">
                <div
                  className="brand-logo-str"
                  onClick={() => {
                    setShow(false);
                    setFynModal(true);
                  }}
                >
                  FynCom
                </div>{' '}
              </div>
              <div className="tutor-widget-body bg-white">
                <div className="message_text_outer">
                  <div className="message_content">Hi there!</div>
                </div>
                <div className="message_text_outer">
                  <div className="message_content">
                    What brings you here today?
                  </div>
                </div>
                {/* available options */}
                <div>
                  <div className="tutor-message-options">
                    <div
                      className="tutor-message-option"
                      onClick={() => handleswitch()}
                    >
                      App Demo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {tutorState.dev ? (
          <div className="tutor-icon-position">
            <div className="message-icon-position">
              <div
                className={show ? '' : 'd-none'}
                onClick={() => {
                  setShow(!show);
                }}
              >
                <X className="message-icon-css" />
              </div>

              <div
                className={!show && !tutorState.isActive ? '' : 'd-none'}
                onClick={() => {
                  setShow(!show);
                }}
              >
                <MessageSquare className="message-icon-css" />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal show={showFynModal} onHide={handleFynModalClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>FynCall</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fyncall />
        </Modal.Body>
      </Modal>

      <WelcomScreenModal show={showWelcomeScreen} close={toggleClose} />
    </React.Fragment>
  );
}

export default TutorModule;
